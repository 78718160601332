import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Pagination,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { JobCardSeeker } from "../components";
import Swal from "sweetalert2";
import { AltJobService, CompanyService } from "../services";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import statesAndCities from '../services/statesAndCities.json'; 
import debounce from "lodash.debounce";

const AltListingPublic = () => {
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [jobsF, setJobsF] = useState([]); // Filtered jobs
  const [totalJobs, setTotalJobs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    isLastPage: false,
  });
  const [lastJob, setLastJob] = useState(null); // Track the last fetched job's ID
  const itemsPerPage = 6; // 6 jobs per page
  const initialItems = 18; // First 18 jobs to load (for the first 3 pages)

  const navigate = useNavigate();
  
  // Create a ref for the job listing section
  const jobListingRef = useRef(null);

  const [filters, setFilters] = useState({
    salary: [
      "20 - 30",
      "30 - 40",
      "40 - 50",
      "50 - 60",
      "60 - 70",
      "70 - 80",
      "80 or more",
    ],
    companies: [],
    setting: [
      "Digital Marketing",
      "Sales/ Customer Service",
      "Web Development / Design",
      "Healthcare",
      "Education",
      "Accounting",
      "Legal",
      "Recruiting/Human Resources",
      "Government Jobs",
    ],
    type: ["Hybrid", "Remote", "Inperson"],
    payrollCategory: ["1099", "W-2"],
    pslf: ["Yes", "No"],
    benefits: ["Yes", "No"],
    isDirectHire: ["Yes", "No"],
    companySize: [
      "1 - 10",
      "11 - 50",
      "51 - 200",
      "201 - 500",
      "501 - 1000",
      "1000+",
    ],
  });

  const [appFilters, setAppFilters] = useState({
    title: "",
    salary: [],
    companies: [],
    setting: [],
    type: [],
    payrollCategory: [],
    pslf: [],
    benefits: [],
    isDirectHire: [],
    companySize: [],
    state: "",
    city: "",
  });

  useEffect(() => {
    if (selectedState) {
      setCityOptions(statesAndCities[selectedState] || []);
      setAppFilters((prevFilters) => ({ ...prevFilters, city: "" }));
    }
  }, [selectedState]);

  const hasActiveFilters = useMemo(() => {
    return Object.values(appFilters).some(
      (filter) => (Array.isArray(filter) ? filter.length > 0 : filter !== "")
    );
  }, [appFilters]);

  // const getAllJobs = useCallback(
  //   async (fetchSize = initialItems, start = null, reset = false) => {
  //     if (loading) return;

  //     try {
  //       setLoading(true);

  //       if (reset) {
  //         setJobs([]);
  //         setLastJob(null); // Reset last job reference
  //         setPagination({
  //           page: 1,
  //           isLastPage: false,
  //         });
  //         setTotalJobs(0);
  //       }

  //       const res = await AltJobService.getAltJobs(fetchSize, start, false);

  //       const isLastPage = res.docs.length < fetchSize;

  //       const jobPromises = res.docs.map(async (i) => {
  //         const jobDetails = i.data();
  //         const rec = await CompanyService.getCompanyDetails(
  //           jobDetails.recruiter
  //         );
  //         const applicants = await i.ref.collection("applicants").get();
  //         return {
  //           ...jobDetails,
  //           id: i.id,
  //           recruiterDetails: rec.data(),
  //           applicants: applicants.docs.map((x) => x.data()),
  //         };
  //       });

  //       const jobAry = await Promise.all(jobPromises);

  //       setJobs((prevJobs) => (reset ? jobAry : [...prevJobs, ...jobAry]));

  //       // Track the last job document (cursor for the next batch)
  //       if (res.docs.length > 0) {
  //         setLastJob(res.docs[res.docs.length - 1]); // Set the last job document
  //       }

  //       setPagination((prevPagination) => ({
  //         ...prevPagination,
  //         isLastPage,
  //       }));

  //       setTotalJobs((prevTotal) =>
  //         reset ? res.size : prevTotal + res.size
  //       );

  //       setFilters((prevFilters) => ({
  //         ...prevFilters,
  //         companies: [
  //           ...new Set([...prevFilters.companies, ...jobAry.map((x) => x.recruiterDetails.name)]),
  //         ],
  //       }));
  //     } catch (error) {
  //       console.error(error);
  //       Swal.fire({ text: "Error while fetching jobs" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [jobs, loading]
  // );

  // const getAllJobs = useCallback(
  //   async (fetchSize = initialItems, start = null, reset = false) => {
  //     if (loading) return;
  
  //     try {
  //       setLoading(true);
  
  //       if (reset) {
  //         setJobs([]); // Clear jobs if reset is true
  //         setLastJob(null); // Reset last job reference
  //         setPagination({
  //           page: 1,
  //           isLastPage: false,
  //         });
  //         setTotalJobs(0);
  //       }
  
  //       const res = await AltJobService.getAltJobs(fetchSize, start, false);
  
  //       const isLastPage = res.docs.length < fetchSize;
  
  //       const jobPromises = res.docs.map(async (i) => {
  //         const jobDetails = i.data();
  //         const rec = await CompanyService.getCompanyDetails(jobDetails.recruiter);
  //         const applicants = await i.ref.collection("applicants").get();
  //         return {
  //           ...jobDetails,
  //           id: i.id,
  //           recruiterDetails: rec.data(),
  //           applicants: applicants.docs.map((x) => x.data()),
  //         };
  //       });
  
  //       const jobAry = await Promise.all(jobPromises);
  
  //       // Avoid duplication by filtering out jobs that are already in the list
  //       setJobs((prevJobs) => {
  //         const existingJobIds = prevJobs.map((job) => job.id);
  //         const filteredJobAry = jobAry.filter((job) => !existingJobIds.includes(job.id));
  //         return reset ? filteredJobAry : [...prevJobs, ...filteredJobAry];
  //       });
  
  //       // Track the last job document (cursor for the next batch)
  //       if (res.docs.length > 0) {
  //         setLastJob(res.docs[res.docs.length - 1]);
  //       }
  
  //       setPagination((prevPagination) => ({
  //         ...prevPagination,
  //         isLastPage,
  //       }));
  
  //       setTotalJobs((prevTotal) => (reset ? res.size : prevTotal + res.size));
  
  //       setFilters((prevFilters) => ({
  //         ...prevFilters,
  //         companies: [
  //           ...new Set([...prevFilters.companies, ...jobAry.map((x) => x.recruiterDetails.name)]),
  //         ],
  //       }));
  //     } catch (error) {
  //       console.error(error);
  //       Swal.fire({ text: "Error while fetching jobs" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [loading, setJobs, setPagination, setTotalJobs, setFilters] // Dependencies to ensure reactivity
  // );
  
  
  const getAllJobs = useCallback(
    async (fetchSize = initialItems, start = null, reset = false) => {
      if (loading) return;
  
      try {
        setLoading(true);
  
        if (reset) {
          setJobs([]); // Reset jobs
          setLastJob(null); // Reset last job reference
          setPagination({
            page: 1,
            isLastPage: false,
          });
          setTotalJobs(0); // Reset total jobs
        }
  
        const res = await AltJobService.getAltJobs(fetchSize, start, false);
  
        const isLastPage = res.docs.length < fetchSize;
  
        const jobPromises = res.docs.map(async (i) => {
          const jobDetails = i.data();
          const rec = await CompanyService.getCompanyDetails(jobDetails.recruiter);
          const applicants = await i.ref.collection("applicants").get();
          return {
            ...jobDetails,
            id: i.id,
            recruiterDetails: rec.data(),
            applicants: applicants.docs.map((x) => x.data()),
          };
        });
  
        const jobAry = await Promise.all(jobPromises);
  
        setJobs((prevJobs) => {
          // Combine the previous jobs with the new jobs, filtering out duplicates
          const newJobs = reset ? jobAry : [...prevJobs, ...jobAry];
          const uniqueJobs = newJobs.reduce((acc, job) => {
            if (!acc.find((existingJob) => existingJob.id === job.id)) {
              acc.push(job);
            }
            return acc;
          }, []);
          
          return uniqueJobs;
        });
  
        // Track the last job document (cursor for the next batch)
        if (res.docs.length > 0) {
          setLastJob(res.docs[res.docs.length - 1]); // Set the last job document
        }
  
        setPagination((prevPagination) => ({
          ...prevPagination,
          isLastPage,
        }));
  
        // Update the total number of jobs
        setTotalJobs((prevTotal) => {
          const currentJobCount = jobAry.length; // Number of jobs fetched in this batch
          return reset ? currentJobCount : prevTotal + currentJobCount; // Total jobs across pages
        });
  
        setFilters((prevFilters) => ({
          ...prevFilters,
          companies: [
            ...new Set([...prevFilters.companies, ...jobAry.map((x) => x.recruiterDetails.name)]),
          ],
        }));
      } catch (error) {
        console.error(error);
        Swal.fire({ text: "Error while fetching jobs" });
      } finally {
        setLoading(false);
      }
    },
    [loading] // Only `loading` should be in the dependency array
  );
  
  
  /// Calculate total pages

/* eslint-disable react-hooks/exhaustive-deps */
useEffect(() => {
  getAllJobs(); // Initial load
}, []);
/* eslint-enable react-hooks/exhaustive-deps */

  
  

  const applyFilters = useCallback(() => {
    let filteredJobs = jobs;

    filteredJobs = filteredJobs.filter((x) => {
      const nameMatch =
        !appFilters.title ||
        x.title.toLowerCase().includes(appFilters.title.toLowerCase());
      const salary =
        !(appFilters.salary.length > 0) || appFilters.salary.includes(x.salaryRange);
      const company =
        !(appFilters.companies.length > 0) ||
        appFilters.companies.includes(x.recruiterDetails.name);
      const type =
        !(appFilters.type.length > 0) || appFilters.type.includes(x.type);
      const payrollCategory =
        !(appFilters.payrollCategory.length > 0) ||
        appFilters.payrollCategory.includes(x.payrollCategory);
      const pslf =
        !(appFilters.pslf.length > 0) || appFilters.pslf.includes(x.pslf);
      const isDirectHire =
        !(appFilters.isDirectHire.length > 0) ||
        appFilters.isDirectHire.includes(x.isDirectHire);
      const benefits =
        !(appFilters.benefits.length > 0) || appFilters.benefits.includes(x.benefits);
      const setting =
        !(appFilters.setting.length > 0) || appFilters.setting.includes(x.setting);
      const cs =
        !(appFilters.companySize.length > 0) ||
        appFilters.companySize.includes(x?.companySize);
      
        const stateMatch = !appFilters.state || appFilters.state === x.state;
        const cityMatch = !appFilters.city || appFilters.city === x.city;

      return (
        nameMatch &&
        salary &&
        company &&
        type &&
        payrollCategory &&
        pslf &&
        isDirectHire &&
        benefits &&
        setting &&
        cs &&
        stateMatch &&  // Ensure the state matches
        cityMatch  
      );
    });

    setJobsF(filteredJobs);
    setTotalJobs(filteredJobs.length); // Set total jobs based on filtered result
  }, [appFilters, jobs]);

  useEffect(() => {
    applyFilters();
  }, [jobs, appFilters, applyFilters]);

  const handleSearch = useCallback(
    debounce(() => {
      getAllJobs(initialItems, null, true);
    }, 300),
    [appFilters, getAllJobs]
  );

  const clearFilter = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All filters will be cleared.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, clear filters!",
    }).then((result) => {
      if (result.isConfirmed) {
        setAppFilters({
          title: "",
          salary: [],
          companies: [],
          setting: [],
          type: [],
          payrollCategory: [],
          pslf: [],
          benefits: [],
          isDirectHire: [],
          companySize: [],
          state: "", // Reset state filter
          city: "", 
        });
        handleSearch();
        setSelectedState(""); // Reset selected state
        setSelectedCity(""); // Reset selected city
        setCityOptions([]); // Clear city options
        handleSearch();
      }
    });
  };

  // Load more jobs (next batch of jobs starting after the last fetched job)
  const loadMoreItems = () => {
    if (!pagination.isLastPage && !loading) {
      getAllJobs(itemsPerPage * 3, lastJob); // Fetch 3 pages of jobs after the last fetched job
    }
  };

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: newPage,
      }));

      // Scroll to the job listings section when page changes
      if (jobListingRef.current) {
        window.scrollTo({
          top: jobListingRef.current.offsetTop - 100, // Adjust offset for any padding or filters
          behavior: "smooth", // Smooth scrolling
        });
      }
    }
  };

  const totalPages = useMemo(() => Math.ceil(jobsF.length / itemsPerPage), [jobsF]);

 

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section className="hero-section">
        <div
          className="hero-wrap"
          style={{ backgroundPositionY: 0.7 * scrollY }}
        >
          <div className="overlay"></div>
          <div className="hero-text">
            {/* <div className="text-center col-lg-8">
              <h1>Find jobs as per your requirement</h1>
              <h3 className="py-3">Alternate Jobs</h3>
            </div> */}
          </div>
        </div>
      </section>
      <Container style={{ marginTop: "35px" }}>
        <Row className="mb-4">
          <Col>
            <div className="filters">
              {/* Filters */}
              <Row
                style={{ padding: "7.5px", paddingBottom: "0px" }}
                className="justify-content-center align-items-center my-2 mt-4"
              >
               <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Job Setting</InputLabel>
                    <Select
                      value={appFilters.setting}
                      label="Job Setting"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          setting: e.target.value,
                        })
                      }
                    >
                      {filters.setting.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Job Type</InputLabel>
                    <Select
                      value={appFilters.type}
                      label="Job Type"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          type: e.target.value,
                        })
                      }
                    >
                      {filters.type.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={3}>
                <FormControl fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setAppFilters({ ...appFilters, state: e.target.value });
                    }}
                    label="State"
                  >
                    <MenuItem value="">-- Select --</MenuItem>
                    {Object.keys(statesAndCities).map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

             
              <Col lg={3}>
  <FormControl fullWidth>
    <InputLabel>City</InputLabel>
    <Select
      value={selectedCity}
      onChange={(e) => {
        setSelectedCity(e.target.value);
        setAppFilters({ ...appFilters, city: e.target.value });
      }}
      label="City"
      disabled={!selectedState}
      style={{ minWidth: '200px' }}  // Add minWidth styling here
      MenuProps={{
        disablePortal: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
    >
      <MenuItem value="">-- Select --</MenuItem>
      {cityOptions.map((city) => (
        <MenuItem key={city} value={city}>
          {city}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Col>
              </Row>
              <Row
                style={{
                  padding: "15px",
                  paddingTop: "7.5",
                  paddingBottom: "0px",
                }}
                className="justify-content-center my-2"
              >
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Payroll Category</InputLabel>
                    <Select
                      value={appFilters.payrollCategory}
                      label="Payroll Category"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          payrollCategory: e.target.value,
                        })
                      }
                    >
                      {filters.payrollCategory.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              {/* New Row Below */}

              <Row
                style={{ padding: "7.5px", paddingTop: "0px" }}
                className="justify-content-center align-items-center my-2 mt-4"
              >
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>PSLF Eligiblity</InputLabel>
                    <Select
                      value={appFilters.pslf}
                      label="Payroll Category"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          pslf: e.target.value,
                        })
                      }
                    >
                      {filters.pslf.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Benefits</InputLabel>
                    <Select
                      value={appFilters.benefits}
                      label="Benefits"
                      onChange={(e) =>
                        setAppFilters({
                          ...appFilters,
                          benefits: e.target.value,
                        })
                      }
                    >
                      {filters.benefits.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row
                style={{ paddingTop: "7.5px" }}
                className="justify-content-center"
              >
                {hasActiveFilters && (
                  <Col lg={3} className="d-grid">
                    <h6
                      style={{
                        paddingTop: "10px",
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      Please Clear Filter if any problems
                    </h6>
                    <button
                      className="btn btn-success"
                      onClick={clearFilter}
                    >
                      Clear
                    </button>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
        <Col xs={6}>
    <h4 style={{ padding: "10px 0" }}>Total Jobs Found: {totalJobs}</h4>
  </Col>
        <Row ref={jobListingRef}>
          {!loading &&
            jobsF
              .slice((pagination.page - 1) * itemsPerPage, pagination.page * itemsPerPage)
              .map((item) => (
                <Col md={4} key={item.id}>
                  <JobCardSeeker
                    job={item}
                    onClick={() => navigate("/manage-jobs/details-alt/" + item.id)}
                  />
                </Col>
              ))}
        </Row>
        {jobsF.length < 1 && !loading && (
          <h3 className="text-secondary text-center my-4">No Jobs Found</h3>
        )}
        {loading && (
          <div className="text-center mt-5 pt-5">
            <Spinner></Spinner>
          </div>
        )}
        {!loading && jobsF.length > 0 && (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <Pagination className="my-2 align-items-center">
                <Pagination.First
                  onClick={() => changePage(1)}
                  disabled={pagination.page === 1 || loading}
                />
                <Pagination.Prev
                  disabled={pagination.page === 1 || loading}
                  onClick={() => changePage(pagination.page - 1)}
                />
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={pagination.page === index + 1}
                    onClick={() => changePage(index + 1)}
                    disabled={loading}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  disabled={pagination.page === totalPages || loading}
                  onClick={() => changePage(pagination.page + 1)}
                />
                <Pagination.Last
                  onClick={() => changePage(totalPages)}
                  disabled={pagination.page === totalPages || loading}
                />
              </Pagination>
            </div>
            {pagination.page === totalPages && !pagination.isLastPage && (
              <div className="d-flex justify-content-center">
                <Button onClick={loadMoreItems}>Load More Jobs</Button>
              </div>
            )}
          </>
        )}
      </Container>
      <div className="mb-5 pb-5"></div>
    </>
  );
};

export default AltListingPublic;