import { db, storage } from "../firebase-config";


export class JobService {
  static createJob = async (data) => {
    const docRef = db.collection("jobs");
    return docRef.add({
      ...data,
      creationTime: new Date().toISOString(),
    });
  };
  static getJobDetails = async (userId) => {
    const docRef = db.collection("jobs").doc(userId);
    console.log(docRef, "doc ref")
    
    return docRef.get();
  };
  
  // static getJobs = async (id) => {
  //   const docRef = db.collection("jobs").where("recruiter", "==", id);
  //   return docRef.get();
  // };

  // static getJobs = async (id, limit = 5, index = null, prev = false) => {
  //   const docRef = index
  //     ? prev
  //       ? db
  //           .collection("jobs")
  //           .where("recruiter", "==", id)  // Filter by recruiter
  //           .limitToLast(limit)
  //           .orderBy("creationTime", "desc")
  //           .endBefore(index)
  //       : db
  //           .collection("jobs")
  //           .where("recruiter", "==", id)  // Filter by recruiter
  //           .limit(limit)
  //           .orderBy("creationTime", "desc")
  //           .startAfter(index)
  //     : db
  //         .collection("jobs")
  //         .where("recruiter", "==", id)  // Filter by recruiter
  //         .limit(limit)
  //         .orderBy("creationTime", "desc");
  
  //   return docRef.get();
  // };

  static getJobs = async (id, limit = 6, index = null, prev = false) => {
    let query = db
    
      .collection("jobs")
      
      .where("recruiter", "==", id)  // Filter by recruiter
      .orderBy("creationTime", "desc");
  
    // Apply pagination with the cursor (index)
    if (index) {
      query = prev
        ? query.endBefore(index).limitToLast(limit)
        : query.startAfter(index).limit(limit);
    } else {
      query = query.limit(limit);
    }
  
    return query.get();
  };

  // Code for the JobsDashboard
  static getTotalJobs = async (id) => {
    const query = db
      .collection("jobs")
      .where("recruiter", "==", id);

  
    const snapshot = await query.get();
    console.log(snapshot.size, "total jobs snapShot")
    return snapshot.size; // Return total number of jobs
  };

 
  static getAllJobs = async (limit = 5, index = null, prev = false) => {
    const docRef = index
      ? prev
        ? db
            .collection("jobs")
            .limitToLast(limit)
            .orderBy("creationTime", "desc")
            .endBefore(index)
        : db
            .collection("jobs")
            .limit(limit)
            .orderBy("creationTime", "desc")
            .startAfter(index)
      : db.collection("jobs").limit(limit).orderBy("creationTime", "desc");
    return docRef.get();
  };

  
  
  static getAppliedJobs = async (userId, collectionName = "jobs") => {
    // Query the jobs collection where the userId is in the applicants array
    const query = db.collection(collectionName).where("applicants", "array-contains", userId);
  
    return query.get().then((querySnapshot) => {
      const appliedJobs = [];
  
      console.log(querySnapshot.size, "Number of jobs found for user:", userId); // Debugging log
  
      // Loop through the job documents that match the query
      querySnapshot.forEach((doc) => {
        appliedJobs.push({ id: doc.id, ...doc.data() }); // Push each job document to the appliedJobs array
      });
  
      console.log(appliedJobs, "Jobs where user applied:", userId); // Debugging log
      return appliedJobs;
    }).catch((error) => {
      console.error("Error fetching jobs:", error); // Error logging
      throw error;
    });
  };
  
  
  // static logJobsAndApplicants = async () => {
  //   // Fetch all jobs from the "jobs" collection
  //   const jobsRef = db.collection("jobs");

  //   return jobsRef.get().then(async (querySnapshot) => {
  //     querySnapshot.forEach(async (doc) => {
  //       console.log(`Job ID: ${doc.id}, Job Data:`, doc.data()); // Log job data
        
  //       const applicantsRef = doc.ref.collection("applicants");
  //       const applicantsSnapshot = await applicantsRef.get();

  //       if (!applicantsSnapshot.empty) {
  //         applicantsSnapshot.forEach((applicantDoc) => {
  //           console.log(`Applicant ID: ${applicantDoc.id}, Applicant Data:`, applicantDoc.data());
  //         });
  //       } else {
  //         console.log(`No applicants found for Job ID: ${doc.id}`);
  //       }
  //     });
  //   }).catch(error => {
  //     console.error("Error fetching jobs or applicants:", error);
  //   });
  // };

  static logJobsAndApplicants = async () => {
    // Fetch all jobs from the "jobs" collection
    const jobsRef = db.collection("jobs");
  
    return jobsRef.get().then(async (querySnapshot) => {
      // Loop through all job documents
      for (const doc of querySnapshot.docs) {
        console.log(`Job ID: ${doc.id}, Job Data:`, doc.data()); // Log job data
        
        // Fetch the "applicants" sub-collection for each job
        const applicantsRef = doc.ref.collection("applicants");
        const applicantsSnapshot = await applicantsRef.get();
  
        // Check if there are any applicants
        if (!applicantsSnapshot.empty) {
          applicantsSnapshot.forEach((applicantDoc) => {
            console.log(`Applicant ID: ${applicantDoc.id}, Applicant Data:`, applicantDoc.data());
          });
        } else {
          console.log(`No applicants found for Job ID: ${doc.id}`);
        }
      }
    }).catch(error => {
      console.error("Error fetching jobs or applicants:", error);
    });
  };
  
  static applyJob = async (jobId, userId) => {
    const applicantRef = db
      .collection("jobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return applicantRef.set({
      id: userId,
      selected: false,
    });
  };

  static checkApplication = async (jobId, userId) => {
    const applicantRef = db
      .collection("jobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return applicantRef.get();
  };
  static getApplicants = async (jobId) => {
    const applicants = db
      .collection("jobs")
      .doc(jobId)
      .collection("applicants");
    return applicants.get();
  };
  


  // static getAppliedJobs = async (userId) => {
  //   const applicantRef = db
  //     .collectionGroup("applicants")
  //     .where("id", "==", userId);
  
  //   return applicantRef.get().then(async (querySnapshot) => {
  //     const jobs = [];
  //     console.log(jobs, "applicant jobs from jobService")
  
  //     for (const doc of querySnapshot.docs) {
  //       const jobRef = doc.ref.parent.parent; // Get the parent job document reference
  //       const jobDoc = await jobRef.get(); // Await the job data
  
  //       if (jobDoc.exists) {
  //         jobs.push(jobDoc.data()); // Push the job data to the array
  //       }
  //     }
  
  //     return jobs; // Return the array of job data
  //   });
  // };

  static getAppliedJobs = async (userId, collectionName = "jobs") => {
    // Fetch all jobs from the specified collection
    const jobsRef = db.collection(collectionName);
  
    return jobsRef.get().then(async (querySnapshot) => {
      const appliedJobs = [];
  
      // Loop through each job document
      for (const doc of querySnapshot.docs) {
        // Get the applicants sub-collection for each job
        const applicantsRef = doc.ref.collection("applicants");
        const applicantsSnapshot = await applicantsRef.where("id", "==", userId).get();
  
        // If the user is found in the applicants sub-collection, add the job to appliedJobs
        if (!applicantsSnapshot.empty) {
          appliedJobs.push({ id: doc.id, ...doc.data() });
        }
      }
  
      console.log(appliedJobs, `Jobs where user (${userId}) applied:`, appliedJobs.length);
      return appliedJobs; // Return the array of applied jobs
    }).catch((error) => {
      console.error("Error fetching applied jobs:", error); // Error logging
      throw error;
    });
  };
  
  
  
  static selectSeeker = async (jobId, userId) => {
    const docRef = db
      .collection("jobs")
      .doc(jobId)
      .collection("applicants")
      .doc(userId);
    return docRef.update({
      selected: true,
    });
  };
  
  
  static closeJob = async (jobId) => {
    const docRef = db.collection("jobs").doc(jobId);
    return docRef.update({
      status: "closed",
    });
  };
  static storeJobsImage = (image, id) => {
    return storage
      .ref(`/jobs`)
      .child(id)
      .putString(image.split("base64,")[1], "base64", {
        contentType: "image/jpg",
      });
  };
}