import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const AffiliateLinkSharer = () => {
  const profile = useSelector((state) => state.authReducer.user); // Get profile data
  const [baseLink, setBaseLink] = useState(""); // Manually entered base link from the company
  const [finalLink, setFinalLink] = useState(""); // Store generated link
  const [errorMessage, setErrorMessage] = useState(""); // Store error message if necessary

  // Function to generate the final link with the profile's affiliate ID
  const generateFinalLink = () => {
    const affiliateId = profile?.affiliateId; // Assuming profile contains an affiliateId field

    if (affiliateId && baseLink) {
      const link = `${baseLink}&ref=${affiliateId}`;
      setFinalLink(link);
      setErrorMessage(""); // Clear any previous errors
    } else {
      setErrorMessage("Affiliate ID or base link not found.");
    }
  };

  return (
    <div>
      <h3>Share Affiliate Link</h3>

      {/* Text field to enter the base link from the company */}
      <TextField
        fullWidth
        label="Enter Base Affiliate Link"
        value={baseLink}
        onChange={(e) => setBaseLink(e.target.value)}
        variant="outlined"
        margin="normal"
      />

      {/* Button to generate the final link */}
      <Button
        variant="contained"
        color="primary"
        onClick={generateFinalLink}
        disabled={!baseLink || !profile?.affiliateId} // Disable button if no base link or affiliate ID
      >
        Generate Final Link
      </Button>

      {/* Display an error message if needed */}
      {errorMessage && (
        <p style={{ color: "red", marginTop: "10px" }}>{errorMessage}</p>
      )}

      {/* Display the generated final link */}
      {finalLink && (
        <div style={{ marginTop: "20px" }}>
          <p>Final Affiliate Link:</p>
          <TextField fullWidth value={finalLink} readOnly />
        </div>
      )}
    </div>
  );
};

export default AffiliateLinkSharer;
