import React, { useState, useEffect, useRef} from "react";
import { Spinner } from 'react-bootstrap';
import avatarHolder from '../assets/avatarHolder.png';
import gradientBanner from '../assets/gradientBanner.png';
import statesAndCities from '../services/statesAndCities.json'; 
import { DashIcons } from "../assets/icons/dashIcons";
import LinkIcon from '../assets/LinkIcon.png';
import {
  InputLabel,
  IconButton,
  TextField,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useLocation } from "react-router";
import { ProfileService } from "../services";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import Swal from "sweetalert2";
import { auth } from "../firebase-config";
import firebase from "firebase/compat/app";

const classifications = ["SLP", "SLPA", "SLP - CF", "Virtual Assistant", "Paraprofessional"];

const MyProfilePage = () => {
  // const [avatarUrl, setAvatarUrl] = useState(avatarHolder); 
  const [avatarUrl, setAvatarUrl] = useState(avatarHolder);
  const [isSaving, setIsSaving] = useState(false);
  const location = useLocation();
  const [selectedState, setSelectedState] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [isMyProfile, setIsMyProfile] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [userId, setUserId] = useState("");
  const signinProvider = firebase.auth().currentUser?.providerData[0]?.providerId;
  const [profileData, setData] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    avatar: "",
    bio: "",
    state: "",
    city: "",
    fb: "",
    insta: "",
    blog: "",
    yt: "",
    twitter: "",
    linkedin: "",
    coverPhoto: "",
    role: "",
    active: "",
    resumeText: "",
    resumeFileUrl: "" 
  });
  const [error, setError] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    avatar: "",
    bio: "",
    state: "",
    city: "",
    notFound: false,
  });


  

  
  
  
  

  

  const handleChange = (field, value) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  
  

  // const handleChange = (field, value) => {
  //   setData((prevState) => ({
  //     ...prevState,
  //     [field]: value,
  //   }));
  // };
  
  
  

  useEffect(() => {
    if (profileData.state) {
      setSelectedState(profileData.state);  // Set selected state from profile data
      setCityOptions(statesAndCities[profileData.state]);  // Set city options based on state
    }
  }, [profileData.state]);
  
  useEffect(() => {
    if (selectedState) {
      setCityOptions(statesAndCities[selectedState]);
    }
  }, [selectedState]);
  
  
  
  

  const isValid = () => {
    let flag = true;
    let errorData = {};
    
    if (profileData.name === "") {
      flag = false;
      errorData.name = "Full Name cannot be blank";
    }
    if (profileData.username === "") {
      flag = false;
      errorData.username = "Username cannot be blank";
    }
    if (profileData.bio === "") {
      flag = false;
      errorData.bio = "Bio cannot be blank";
    }
    if (profileData.city === "") {
      flag = false;
      errorData.city = "City cannot be blank";
    }
    if (profileData.state === "") {
      flag = false;
      errorData.state = "State cannot be blank";
    }
  
    // Check if avatar is the default avatar or empty
    if (profileData.avatar === "" || profileData.avatar === avatarHolder) {
      flag = false;
      errorData.avatar = "Please upload an avatar image";
    }
  
    setError(errorData);
    return flag;
  };

  

  useEffect(() => {
    const storedAvatarUrl = localStorage.getItem("profilePictureUrl");
    if (storedAvatarUrl) {
      setAvatarUrl(storedAvatarUrl);
    }
  }, [avatarUrl]);
  

  useEffect(() => {
    let userIdVar;
  
    const fetchUserProfile = async () => {
      if (!editMode && userIdVar) {
        try {
          const res = await ProfileService.getProfileDetails(userIdVar);
          if (res.exists) {
            const data = res.data();
            setData({
              ...data,
              state: data.state || "",  // Set state from profile data
              city: data.city || "",    // Set city from profile data
            });
  
            // Fetch avatar if needed
            if (data.avatar) {
              const avatarUrl = await ProfileService.getProfileAvatarUrl(userIdVar);
              setAvatarUrl(avatarUrl);
            }
            setError({ notFound: false });
          } else {
            setError({ notFound: true });
          }
        } catch (error) {
          Swal.fire({ title: "Error", text: error.message });
        }
      }
    };
  
    if (location.pathname.startsWith("/profiles/")) {
      userIdVar = location.pathname.split("/").pop();
      setIsMyProfile(false);
      fetchUserProfile();
    } else {
      const fetchUser = async () => {
        while (!auth.currentUser) {
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        userIdVar = auth.currentUser.uid;
        setUserId(userIdVar);
        fetchUserProfile();
      };
  
      fetchUser();
    }
  }, [location.pathname, editMode]);
  
  
  
  
  
  

  
  
  
  

 



  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const result = reader.result; // base64 string
        if (type === "avatar") {
          // Directly update the avatar in the profile data for immediate preview
          setData({ ...profileData, avatar: result });
          setAvatarUrl(result);  // Set preview immediately
        } else if (type === "cover") {
          // For cover photo upload
          setData({ ...profileData, coverPhoto: result });
        } else if (type === "resume") {
          // Handle resume upload if needed
          const fileUrl = await ProfileService.uploadResume(file, userId);
          setData({ ...profileData, resumeFileUrl: fileUrl });
        }
      };
      reader.readAsDataURL(file); // Convert the file to a base64 string for immediate preview
    }
  };
  
  // Save profile function with avatar upload handling
  const saveProfile = async () => {
    if (isValid()) {
      setIsSaving(true);
      try {
        // Save the profile details to the database
        await ProfileService.updateProfileDetails(userId, profileData);
        Swal.fire("Profile Updated!");
        setEditMode(false);
  
        // Upload the avatar if it's changed and not from Facebook
        if (profileData.avatar && signinProvider !== "facebook.com") {
          const updatedAvatarUrl = await ProfileService.storeProfileImage(
            profileData.avatar.split("base64,")[1],
            userId
          );
          setAvatarUrl(updatedAvatarUrl);
          
          window.dispatchEvent(new Event("profileUpdated"));
        }
      } catch (error) {
        Swal.fire(error.message);
      } finally {
        setIsSaving(false);
      }
    }
  };
  
  
  
  
  
  
 
  
  
  const uploadCover = useRef(null);
  const uploadDp = useRef(null);
  const copyLink = () => {
    const url = `${window.location.origin}/profile/${userId}`;
    navigator.clipboard.writeText(url);
    Swal.fire("URL copied to clipboard: " + url);
  };

  console.log("Profile data Errs:", profileData);


  return (
    <>
      {!error.notFound && (
        <Container className="profile-page-container">
          <Row className="justify-content-center">
            <Col md={12} className="p-0">
              <div className="profile-cover-bg">
                <img
                  src={
                    profileData.coverPhoto
                      ? profileData.coverPhoto
                      : `https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/profile%2Fcover%2F${userId}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`
                  }
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = gradientBanner; // Fallback to placeholder
                  }}
                />
                {editMode && (
                  <span className="edit-cover-btn">
                    <IconButton
                      color="primary"
                      onClick={() => uploadCover.current.click()}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                )}
              </div>
            </Col>
          </Row>
  
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="d-flex align-items-center profile-cover">
              <div className="avatar-container p-0 me-3">
  <img
    src={avatarUrl}
    alt="User Avatar"
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = avatarHolder; // Fallback to placeholder
    }}
    style={{
      width: "150px",
      height: "150px",
      objectFit: "cover",
    }}
  />
  {editMode && (
    <span className="edit-dp-btn">
      <IconButton
        color="primary"
        onClick={() => uploadDp.current.click()}
      >
        <EditIcon />
      </IconButton>
    </span>
  )}
  {error.avatar && (
    <p className="text-danger">{error.avatar}</p>
  )}
</div>

  
                <div
                  className="profile-cover-body flex-grow-1"
                  style={{ paddingLeft: "20px" }}
                >
                  <h3 className="d-flex align-items-center mb-2">
                    {profileData.name}
                    {isMyProfile && (
                      <IconButton
                        color="primary"
                        onClick={() => setEditMode(true)}
                        className="ms-2"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton
                      color="primary"
                      onClick={copyLink}
                      className="ms-2"
                    >
                      <ShareIcon />
                    </IconButton>
                  </h3>
                  {profileData.username && (
                    <h6 style={{ paddingBottom: "10px" }} className="mb-1">
                      @{profileData.username}
                    </h6>
                  )}
                  <p className="mb-0">
                    {profileData.role} | {profileData.city}, {profileData.state}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
  
          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <h3>Profile Details</h3>
            </Col>
          </Row>
  
          <Row className="justify-content-center">
            <Col md={3}>
              <TextField
                error={!!error.name}
                label="Full Name"
                variant="standard"
                placeholder="Enter Full Name"
                fullWidth
                value={profileData.name}
                helperText={error.name}
                onChange={(e) =>
                  setData({ ...profileData, name: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
            <Col md={3}>
              <TextField
                error={!!error.username}
                label="Username"
                variant="standard"
                placeholder="Enter Username"
                fullWidth
                helperText={error.username}
                value={profileData.username}
                onChange={(e) =>
                  setData({ ...profileData, username: e.target.value })
                }
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
          </Row>
  
          <Row className="justify-content-center my-3">
            <Col md={3}>
              <FormControl fullWidth>
                <InputLabel>State</InputLabel>
                <Select
                  value={profileData.state}
                  onChange={(e) => {
                    handleChange("state", e.target.value);
                    setSelectedState(e.target.value);
                  }}
                  label="State"
                  required
                  inputProps={{
                    readOnly: !editMode,
                  }}
                  disabled={!editMode}
                >
                  <MenuItem value="">-- Select --</MenuItem>
                  {Object.keys(statesAndCities).map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
  
            <Col md={3}>
  <FormControl fullWidth>
    <InputLabel>City</InputLabel>
    <Select
      value={profileData.city}  // Keep the city value updated
      onChange={(e) => handleChange("city", e.target.value)}  // Allow editing city independently
      label="City"
      inputProps={{
        readOnly: !editMode,
      }}
      disabled={!editMode || !selectedState}  // Disable if there's no state selected
    >
      <MenuItem value="">-- Select --</MenuItem>
      {cityOptions.map((city, index) => (
        <MenuItem key={`${city}-${index}`} value={city}>
          {city}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Col>

          </Row>
  
          <Row className="justify-content-center my-2">
            <Col md={6}>
              <h3>Professional Details</h3>
            </Col>
          </Row>
  
          <Row className="justify-content-center my-3">
            <Col md={6}>
              <TextField
                label="Bio"
                placeholder="Write about yourself and your service..."
                multiline
                rows={2}
                variant="filled"
                helperText={error.bio}
                error={!!error.bio}
                value={profileData.bio}
                fullWidth
                onChange={(e) => setData({ ...profileData, bio: e.target.value })}
                inputProps={{
                  readOnly: !editMode,
                }}
              />
            </Col>
          </Row>
  
          <Row className="justify-content-center my-3">
  {/* Job Role with Instructional Text */}
  <Col md={3}>
    <div style={{ marginBottom: "8px" }}>
      <small style={{ color: "gray" }}>
        Select the job role that best matches your background and/or experience. You can elaborate in bio or resume.  
      </small>
    </div>
    <FormControl fullWidth>
      <InputLabel>Job Role</InputLabel>
      <Select
        value={profileData?.role}
        label="Job Role"
        onChange={(e) => setData({ ...profileData, role: e.target.value })}
        inputProps={{
          readOnly: !editMode,
        }}
      >
        <MenuItem key="blank" value="">
          -- Select --
        </MenuItem>
        {classifications.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Col>

  {/* Actively Looking with Instructional Text */}
  <Col md={3}>
    <div style={{ marginBottom: "8px" }}>
      <small style={{ color: "gray" }}>
        If you Select "YES" then a company can search your profile to contact without applying to a job.
      </small>
    </div>
    <FormControl fullWidth>
      <InputLabel>Actively Looking</InputLabel>
      <Select
        value={profileData?.active}
        label="Actively Looking"
        onChange={(e) => setData({ ...profileData, active: e.target.value })}
        inputProps={{
          readOnly: !editMode,
        }}
      >
        <MenuItem key="1" value="YES">
          YES
        </MenuItem>
        <MenuItem key="2" value="NO">
          NO
        </MenuItem>
      </Select>
    </FormControl>
  </Col>
</Row>

  
          {/* Display the resume when not in edit mode */}
          {!editMode && (profileData.resumeText || profileData.resumeFileUrl) && (
            <Row className="justify-content-center my-3">
              <Col md={6}>
                <div className="d-flex justify-content-between align-items-center">
                  <h3>Resume</h3>
                  {/* Display the uploaded resume link on the right of the Resume title */}
                  {profileData.resumeFileUrl && (
                    <a 
                      href={profileData.resumeFileUrl} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{ color: "#007bff", textDecoration: "underline" }}
                    >
                      View Uploaded Resume
                    </a>
                  )}
                </div>
                {/* Display the pasted resume text if available */}
                {profileData.resumeText && (
                  <div
                    className="resume-text p-3 mt-2"
                    style={{
                      backgroundColor: "#f5f5f5", 
                      borderRadius: "4px", 
                      border: "1px solid #ddd",
                      whiteSpace: "pre-wrap", 
                    }}
                  >
                    <pre style={{ marginBottom: "0" }}>{profileData.resumeText}</pre>
                  </div>
                )}
              </Col>
            </Row>
          )}
  
          {/* Edit the resume in edit mode */}
          {editMode && (
            <>
              <Row className="justify-content-center my-3">
                <Col md={6}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Resume</h3>
                    {/* Show link to uploaded resume if it exists */}
                    {profileData.resumeFileUrl && (
                      <a 
                        href={profileData.resumeFileUrl} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: "#007bff", textDecoration: "underline" }}
                      >
                        View Uploaded Resume
                      </a>
                    )}
                  </div>
                  
                  {/* Display and edit pasted resume */}
                  <TextField
                    label="Paste Resume"
                    placeholder="Paste your resume here"
                    multiline
                    rows={4}
                    variant="filled"
                    value={profileData.resumeText || ""}  
                    fullWidth
                    onChange={(e) => handleChange("resumeText", e.target.value)}
                  />
  
                  {/* Button to upload PDF/DOC resume */}
                  <Button
                    variant="outline-secondary"
                    onClick={() => document.getElementById("resume-upload").click()}
                    className="mt-3"
                  >
                    Upload Resume (PDF/DOC)
                  </Button>
  
                  {/* Hidden file input for uploading resumes */}
                  <input
                    type="file"
                    id="resume-upload"
                    hidden
                    accept=".pdf,.doc,.docx"
                    onChange={(e) => handleFileUpload(e, "resume")}
                  />
                </Col>
              </Row>
            </>
          )}
            {editMode && (
            <>
              <Row className="justify-content-center my-3">
                <Col md={6}>
                  <h3>Link Tree</h3>
                  <p>"Make sure to put https://url.com"</p>
                </Col>
              </Row>

              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Facebook Link"
                    label="Facebook"
                    variant="standard"
                    fullWidth
                    value={profileData.fb}
                    onChange={(e) =>
                      setData({ ...profileData, fb: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Linkedin Link"
                    label="Linkedin"
                    variant="standard"
                    fullWidth
                    value={profileData.linkedin}
                    onChange={(e) =>
                      setData({ ...profileData, linkedin: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Link"
                    label="Misc. Link"
                    variant="standard"
                    fullWidth
                    value={profileData.blog}
                    onChange={(e) =>
                      setData({ ...profileData, blog: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Instagram Link"
                    label="Instagram"
                    variant="standard"
                    fullWidth
                    value={profileData.insta}
                    onChange={(e) =>
                      setData({ ...profileData, insta: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center my-2">
                <Col md={3}>
                  <TextField
                    placeholder="Enter Youtube Link"
                    label="Youtube"
                    variant="standard"
                    fullWidth
                    value={profileData.yt}
                    onChange={(e) =>
                      setData({ ...profileData, yt: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    placeholder="Enter Twitter Link"
                    label="Twitter"
                    variant="standard"
                    fullWidth
                    value={profileData.twitter}
                    onChange={(e) =>
                      setData({ ...profileData, twitter: e.target.value })
                    }
                    inputProps={{
                      readOnly: !editMode,
                    }}
                  />
                </Col>
              </Row>
            </>
          )}     
          {!editMode && (
            <>
              {(profileData.fb ||
                profileData.insta ||
                profileData.blog ||
                profileData.linkedin ||
                profileData.twitter ||
                profileData.yt) && (
                <Row className="justify-content-center my-3">
                  <Col md={6}>
                    <h3>Link Tree</h3>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-center my-2">
                <Col md={6}>
                  <div className="social-links-container">
                    {profileData.fb && (
                      <span className="social-links">
                        <a href={profileData.fb} target="_blank"rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.fb} alt="" ></img>
                        </a>
                      </span>
                    )}
                    {profileData.linkedin && (
                      <span className="social-links">
                        <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.linkedin} alt="" ></img>
                        </a>
                      </span>
                    )}
                    {profileData.twitter && (
                      <span className="social-links">
                        <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.twitter} alt="" ></img>
                        </a>
                      </span>
                    )}
                    {profileData.insta && (
                      <span className="social-links">
                        <a href={profileData.insta} target="_blank"rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.insta} alt="" ></img>
                        </a>
                      </span>
                    )}
                    {profileData.yt && (
                      <span className="social-links">
                          <a href={profileData.yt} target="_blank" rel="noopener noreferrer">
                          <img src={DashIcons.socialMedia.yt} alt="" ></img>
                        </a>
                      </span>
                    )}
                    {profileData.blog && (
                      <span className="social-links">
                           <a href={profileData.blog} target="_blank" rel="noopener noreferrer">
                          <img src={LinkIcon} alt="" ></img>
                        </a>
                      </span>
                    )}
                  </div>
               
                </Col>
              </Row>
            </>
          )}
  
  {editMode && (
  <Row className="justify-content-center my-3">
    <Col md={3} className="d-grid">
      <Button onClick={saveProfile} disabled={isSaving}>
        {isSaving ? (
          <>
            <Spinner animation="border" size="sm" className="me-2" />
            Saving...
          </>
        ) : (
          "Save"
        )}
      </Button>
    </Col>
    <Col md={3} className="d-grid">
      <Button
        onClick={() => setEditMode(false)}
        variant="outline-primary"
      >
        Cancel
      </Button>
    </Col>
  </Row>
)}

  
          {/* Hidden inputs for file uploads */}
          <input
            type="file"
            hidden
            name="dp"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, "avatar")}
            ref={uploadDp}
          />
          <input
            type="file"
            hidden
            name="cover"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, "cover")}
            ref={uploadCover}
          />
        </Container>
      )}
      {error.notFound && <h3 className="text-center my-5">Not Found</h3>}
  
    </>
  );
  
  
  
};

export default MyProfilePage;
