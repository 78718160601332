import React, { useEffect, useState } from "react";
import ContactUsForm from "../components/contact/ContactUsForm";
import "./Home.css";

// testing code

export default function ContactUs() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <section className="hero-section">
        <div className="hero-wrap" style={{ backgroundPositionY: 0.7 * scrollY }}>
          <div className="overlay"></div>
          <div className="hero-text">
            {/* <div className="text-center col-lg-8">
              <h1>Home {">"} Contact</h1>
              <h3 className="py-3">Contact Us</h3>
            </div> */}
          </div>
        </div>
      </section>

      <div className="d-flex align-items-center justify-content-center mb-5">
        <ContactUsForm />
      </div>
    </>
  );
}
