import React, { useState, useEffect } from 'react';
import {  Typography} from '@mui/material';


// Dummy data for now, can be replaced with actual API calls to fetch payout data.
const dummyPayouts = [
    { id: 1, profileName: 'John Doe', pendingAmount: 100, status: 'Pending' },
    { id: 2, profileName: 'Jane Smith', pendingAmount: 150, status: 'Paid' },
];

const AffiliatePayouts = () => {
    const [payouts, setPayouts] = useState([]);

    useEffect(() => {
        // Replace with actual API or Firebase call to fetch payout data
        setPayouts(dummyPayouts);
    }, []);

    const handlePayout = (id) => {
        // Logic to handle marking a payout as completed (e.g., updating the database)
        setPayouts(
            payouts.map((payout) =>
                payout.id === id ? { ...payout, status: 'Paid' } : payout
            )
        );
    };

    return (
        <div className="affiliate-payouts">
           <Typography variant="h5" style={{  marginBottom: '30px', color: 'gray' }}>
        Affiliate Pay Out
      </Typography>
            <table>
                <thead>
                    <tr>
                        <th>Profile Name</th>
                        <th>Pending Amount</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {payouts.map((payout) => (
                        <tr key={payout.id}>
                            <td>{payout.profileName}</td>
                            <td>${payout.pendingAmount}</td>
                            <td>{payout.status}</td>
                            <td>
                                {payout.status === 'Pending' ? (
                                    <button onClick={() => handlePayout(payout.id)}>
                                        Pay Now
                                    </button>
                                ) : (
                                    'Paid'
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AffiliatePayouts;
