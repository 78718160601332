import { Card, Button, Spinner } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./StripeCSS.css";
import { onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, db } from "./firebase-config";
import { checkStripeConnect } from "./services/CreateStripeAccount";
import StripeOnboardingComplete from './components/StripeOnboardingComplete';

export default function Stripe() {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [loading, setLoading] = useState(true);
  const [portalLoading, setPortalLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productLoading, setProductLoading] = useState({});
  const [hasStripeConnect, setHasStripeConnect] = useState(false);
  const [onboardingLink, setOnboardingLink] = useState("");
  const [userType, setUserType] = useState(""); 

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const currentUser = user.uid;
        console.log(currentUser, "currentUSer Stripe");

        startDataListeners(currentUser);
        fetchProducts();

        try {
          const companyDoc = await db.collection("companies").doc(currentUser).get();

          if (companyDoc.exists) {
            setUserType("company");
            const stripeConnectResult = await checkStripeConnect(currentUser, "company");
            setHasStripeConnect(stripeConnectResult.hasStripeConnect);
            setOnboardingLink(stripeConnectResult.onboardingLink);
          } else {
            const userDoc = await db.collection("users").doc(currentUser).get();

            if (userDoc.exists) {
              setUserType("profile");
              const stripeConnectResult = await checkStripeConnect(currentUser, "profile");
              setHasStripeConnect(stripeConnectResult.hasStripeConnect);
              setOnboardingLink(stripeConnectResult.onboardingLink);
            } else {
              console.error(`Document for user ${currentUser} does not exist in both collections.`);
            }
          }
        } catch (error) {
          console.error("Error checking Stripe Connect:", error);
        }
      } else {
        setLoading(false);
      }
    });
  }, []);

  const fetchProducts = async () => {
    const productsRef = db.collection("products").where("active", "==", true);
    const productsSnap = await productsRef.get();

    const productsList = [];
    for (const productDoc of productsSnap.docs) {
      const productData = productDoc.data();
      const pricesSnap = await productDoc.ref.collection("prices").get();

      const prices = pricesSnap.docs.map((priceDoc) => ({
        id: priceDoc.id,
        ...priceDoc.data(),
      }));

      productsList.push({ ...productData, prices });
    }
    setProducts(productsList);
  };

  // The missing subscribe function
  const subscribe = async (event, productId, priceId) => {
    event.preventDefault();
    setProductLoading((prevState) => ({
      ...prevState,
      [productId]: true,
    }));

    const selectedPrice = {
      price: priceId,
      quantity: 1,
    };

    const checkoutSession = {
      automatic_tax: true,
      tax_id_collection: true,
      collect_shipping_address: true,
      allow_promotion_codes: true,
      line_items: [selectedPrice],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    };

    const docRef = await db.collection("customers").doc(auth.currentUser.uid).collection("checkout_sessions").add(checkoutSession);

    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        alert(`An error occurred: ${error.message}`);
        setProductLoading((prevState) => ({
          ...prevState,
          [productId]: false,
        }));
      }
      if (url) {
        window.location.assign(url);
      }
    });
  };

  const renderProducts = () => {
    return (
      <div className="products">
        {products.map((product) => (
          <Card key={product.name} style={{ width: "300px", padding: "15px", margin: "10px" }}>
            {product.images && product.images.length > 0 && (
              <img
                src={product.images[0]}
                alt={product.name}
                style={{ width: "100%", height: "150px", objectFit: "cover", marginBottom: "10px" }}
              />
            )}
            <h2 style={{ fontSize: "18px", margin: "10px 0" }}>{product.name}</h2>
            <p style={{ fontSize: "14px", color: "#555", marginBottom: "10px" }}>{product.description}</p>
            <form onSubmit={(event) => subscribe(event, product.name, product.prices[0].id)}>
              <label htmlFor="price">Choose pricing plan:</label>
              <select id="price" name="price" className="price-select">
                {product.prices.map((price) => (
                  <option key={price.id} value={price.id}>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: price.currency,
                    }).format(price.unit_amount / 100)}{" "}
                    per {price.interval}
                  </option>
                ))}
              </select>
              <Button type="submit" variant="primary" fullWidth style={{ marginTop: "10px" }} disabled={productLoading[product.name]}>
                {productLoading[product.name] ? <Spinner animation="border" size="sm" /> : "Subscribe"}
              </Button>
            </form>
          </Card>
        ))}
      </div>
    );
  };

  async function startDataListeners(currentUser) {
    db.collection("customers")
      .doc(currentUser)
      .collection("subscriptions")
      .where("status", "in", ["trialing", "active"])
      .onSnapshot(async (snapshot) => {
        if (!snapshot.empty) {
          setHasActiveSubscription(true);
        } else {
          setHasActiveSubscription(false);
        }
        setLoading(false);
      });
  }

  const handlePortalAccess = async () => {
    setPortalLoading(true);
    try {
      const functions = getFunctions();
      const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url);
    } catch (error) {
      alert(`Error accessing customer portal: ${error.message}`);
    } finally {
      setPortalLoading(false);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Stripe Subscriptions</title>
      </Helmet>
  
      <div id="subscribe">
        {/* Show products to subscribe only if they don't have an active subscription and it's not loading */}
        {userType === "company" && !hasActiveSubscription && !loading && (
          <section className="products">{renderProducts()}</section>
        )}
      </div>
  
      {/* If the user has an active subscription but does NOT have a Stripe Connect account, show onboarding link */}
      {!loading && !hasStripeConnect && (
        <section id="stripe-onboarding">
          <h2>Complete Stripe Connect Onboarding</h2>
          <p>You need to set up your Stripe Connect account to accept payments.</p>
          <Button href={onboardingLink} target="_blank" variant="primary">
            Complete Onboarding
          </Button>
        </section>
      )}
  
      {/* If the user is a company and has completed onboarding, show both subscription management and onboarding complete */}
      {userType === "company" && hasStripeConnect && (
        <section id="manage-subscription">
          <h2>My subscription</h2>
          <Button onClick={handlePortalAccess} variant="primary" disabled={portalLoading}>
            {portalLoading ? <Spinner animation="border" size="sm" /> : "Manage Subscription"}
          </Button>
          {/* Also show onboarding complete */}
          <StripeOnboardingComplete />
        </section>
      )}
  
      {/* If the user is a profile and has completed onboarding */}
      {userType === "profile" && hasStripeConnect && (
        <StripeOnboardingComplete />
      )}
  
      <div id="firebaseui-auth-container"></div>
    </Fragment>
  );
}  
