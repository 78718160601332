import React, { useState, useEffect } from "react";
import statesAndCities from '../../services/statesAndCities.json'; 
import firebase from "firebase/compat/app";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Modal
  
} from "@mui/material";
import { FirebaseAuthService, AltJobService } from "../../services";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { postJobInGroup } from "../../services/FacebookPost";
import { Spinner } from 'react-bootstrap';
// const companySizes = [
//   "1 - 10",
//   "11 - 50",
//   "51 - 200",
//   "201 - 500",
//   "501 - 1000",
//   "1000+",
// ];

const salaryRangeOptions = [
  "20 - 30",
  "30 - 40",
  "40 - 50",
  "50 - 60",
  "60 - 70",
  "70 - 80",
  "80 or more",
];

const jobtype = ["Hybrid", "Remote", "Inperson"];
const payrollCategory = ["1099", "W-2"];
const settings = [
  "Digital Marketing",
  "Sales/ Customer Service",
  "Web Development / Design",
  "Healthcare",
  "Education",
  "Accounting",
  "Legal",
  "Recruiting/Human Resources",
  "Government Jobs",
];

const altJobHours = [
  "PRN",
  "Part-Time",
  "Full-Time",
  
];

const CreateAltJob = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.authReducer.user);
  const [selectedState, setSelectedState] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  
  const [formData, setFormData] = useState({
    benefits: "",
    benefitDetails: "",
    salaryRange: "",
    isIndirectTimePaid: "",
    inDirectTimePaidDetails: "",
    status: "active",
    title: "",
    type: "",
    setting: "",
    companySize: "",
    pslf: "",
    pslfDetails: "",
    altJobHours: "",
    state: "",
    city: "",
  });
  // const [locSuggestions, setLocSuggestions] = useState([]);
  // Handle form field changes
const handleChange = (field, value) => {
  setFormData((prevState) => {
    const updatedState = { ...prevState, [field]: value };

    // Clear PSLF details if 'No'
    if (field === "pslf" && value === "No") {
      updatedState.pslfDetails = "";
    }

    // Clear benefit details if 'No'
    if (field === "benefits" && value === "No") {
      updatedState.benefitDetails = "";
    }

    // Clear indirect time paid details if 'No'
    if (field === "isIndirectTimePaid" && value === "No") {
      updatedState.inDirectTimePaidDetails = "";
    }

    // When state changes, reset the city
    if (field === "state") {
      setSelectedState(value);
      updatedState.city = ""; // Reset city when state changes
    }

    return updatedState;
  });
};



  const handleSubmit = async (event) => {
    setLoading(true);
    const user = FirebaseAuthService.getUserId();
    event.preventDefault();
    try {
      const res = await AltJobService.createJob({
        ...formData,
        recruiter: user,
        company: userProfile.name,
        applicants: {},
      });
      if (formData.imageUrl) {
        AltJobService.storeJobsImage(formData.imageUrl, res.id);
      }
      setTimeout(() => {
        setLoading(false);
        // alert('Form submitted successfully!');
      }, 3000);
      Swal.fire({ text: "Job Created Successfully" });
      const signinProvider = firebase.auth().currentUser?.providerData[0]?.providerId;

      console.log(signinProvider ,'testProvid')
      if (signinProvider === "facebook.com") {

        postJobInGroup(
          `${formData.title} | ${formData.type} | ${formData.setting}`,
           `https://slpmarket.com/#/manage-jobs/details-alt/${res.id}`,
            `https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/jobs%2F${res.id}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`
          )
             console.log(postJobInGroup)
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } 
      navigate("/manage-jobs");
    } catch (error) {
      console.log(error);
      // if (error.message.toLowerCase().includes("imageurl") && error.message.toLowerCase().includes("longer than")) {
      //   Swal.fire({ text: "The image is too big. Please upload a smaller file." });
      // } else {
      //   Swal.fire({ text: "Error while posting in the group" });
      // }
      // Swal.fire(error.message);
      if (error.message.toLowerCase().includes("imageurl")) {
        Swal.fire({ text: "Image Error - The image cannot be longer than 1 MB. Please make sure image type is PNG, JPG, or GIF" });
      } else {
        Swal.fire({ text: "Error while posting in the group" });
      }
      
      setLoading(false);  // Replace with your loading state handler

    }
  }
  
  const isSearchForm = false;
  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result; // base64 string
        setFormData({ ...formData, [type]: result });
      };
      reader.readAsDataURL(file);
    }
  };

  // Update city options when state changes
useEffect(() => {
  if (selectedState) {
    setCityOptions(statesAndCities[selectedState]);
    setFormData((prevState) => ({
      ...prevState,
      city: prevState.city || '', // Preserve the selected city if it exists
    }));
  }
}, [selectedState]);

  return (
    <form
      className="my-5"
      onSubmit={handleSubmit}
      style={{ width: isSearchForm ? "100%" : "60%", margin: "auto" }}
    >
       <div
                style={{
                  borderRadius: "15px",
                  boxShadow: "5px 5px 20px #0003",
                  overflow: "hidden",
                  padding: "20px",
                  margin: "40px",
                  minWidth: "316px",
                  // marginLeft:"25px",
                  backgroundColor: "#f6f6f6",
                }}
              >
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ marginTop: "10px" }}
      >
        <Grid item xs={isSearchForm ? 4 : 12}>
          <TextField
            type="text"
            variant="outlined"
            color="primary"
            label="Job Title"
            onChange={(e) => handleChange("title", e.target.value)}
            value={formData?.title}
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            required={!isSearchForm}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          />
        </Grid>
        {!isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Description"
              onChange={(e) => handleChange("description", e.target.value)}
              value={formData?.description}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              multiline
              rows={4}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}
      
        {!isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
          {/* Modal Info Button */}
          <Button 
    onClick={handleOpen} 
    variant="outlined" 
    size="small" 
    sx={{ mb: 1, mt: -1 }} // Reduced margin-top
  >
    Image Upload Info
  </Button>
        
          {/* Modal Definition */}
          <Modal open={open} onClose={handleClose}>
            <div style={{ padding: '20px', background: '#fff', margin: '100px auto', maxWidth: '400px' }}>
              <h3>Image Upload Guidelines</h3>
           
              <p>Please upload images in JPG, PNG, or GIF format.</p>
              <p>Max size allowed: 1 MB.</p>
              <p>Try a GIF! But make sure its not too big.</p>
              
              <Button onClick={handleClose} variant="contained">
                Close
              </Button>
            </div>
          </Modal>
        
          {/* File Upload Field */}
          <TextField
            type="file"
            variant="outlined"
            color="primary"
            name="cover"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, "imageUrl")}
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
            placeholder="Upload Cover Image"
          />
        </Grid>
        )}

          <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Job Setting</InputLabel>
            <Select
              value={formData?.setting}
              label="Job Setting"
              onChange={(e) => handleChange("setting", e.target.value)}
              required={!isSearchForm}
            >
               <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {settings.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
        <FormControl fullWidth size="medium" sx={{ mb: 2 }}>
              <InputLabel>State</InputLabel>
              <Select
                value={formData.state}
                onChange={(e) => {
                  handleChange("state", e.target.value);
                  setSelectedState(e.target.value);
                }}
                label="State"
                required
              >
                <MenuItem value="">-- Select --</MenuItem>
                {Object.keys(statesAndCities).map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* City */}
          {/* City Dropdown */}
<Grid item xs={12} sm={6}>
  <FormControl fullWidth size="medium" sx={{ mb: 2 }}>
    <InputLabel>City</InputLabel>
    <Select
      value={formData.city}
      onChange={(e) => handleChange("city", e.target.value)}
      label="City"
      required
    >
      <MenuItem value="">-- Select --</MenuItem>
      {cityOptions.map((city, index) => (
        <MenuItem key={`${city}-${selectedState}-${index}`} value={city}>
          {city}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>


        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Job type</InputLabel>
            <Select
              value={formData?.type}
              label="Job type"
              onChange={(e) => handleChange("type", e.target.value)}
              required={!isSearchForm}
            >
               <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {jobtype.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Payroll Category</InputLabel>
            <Select
              value={formData?.payrollCategory}
              label="Payroll Category"
              onChange={(e) => handleChange("payrollCategory", e.target.value)}
              required={!isSearchForm}
            >
               <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {payrollCategory.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Job Hours</InputLabel>
            <Select
              value={formData?.altJobHours}
              label="Job Hours"
              onChange={(e) => handleChange("altJobHours", e.target.value)}
              required={!isSearchForm}
            >
               <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {altJobHours.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Annual Salary range</InputLabel>
            <Select
              value={formData?.salaryRange}
              label="Annual Salary range"
              onChange={(e) => handleChange("salaryRange", e.target.value)}
              required={!isSearchForm}
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {salaryRangeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Benefits</InputLabel>
            <Select
              value={formData?.benefits}
              label="Benefits"
              onChange={(e) => handleChange("benefits", e.target.value)}
              required={!isSearchForm}
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {["Yes", "No"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {formData?.benefits === "Yes" && !isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Please explain the benefits"
              onChange={(e) => handleChange("benefitDetails", e.target.value)}
              value={formData?.benefitDetails}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              multiline
              rows={4}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}



        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>PSLF Eligible?</InputLabel>
            <Select
              value={formData?.pslf}
              label="pslf"
              onChange={(e) => handleChange("pslf", e.target.value)}
              required={!isSearchForm}
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {["Yes", "No"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {formData?.pslf === "Yes" && !isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Please explain the PSLF"
              onChange={(e) => handleChange("pslfDetails", e.target.value)}
              value={formData?.pslfDetails}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              multiline
              rows={4}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}
   
     
        
    
     
       
        {!isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <center>
            <Button
                variant="contained"
                size="large"
                className="w-100"
                color="primary"
                type="submit">
              
              {loading ? 'Loading...' : 'Submit'}
              </Button>
            
            {loading && (
              <Spinner
                animation="border"
                role="status"
                style={{ marginLeft: '10px' }}
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            </center>
          </Grid>
        )}
      </Grid>
      </div>
    </form>
  );
};

export default CreateAltJob;
