import React, { useEffect, useState } from 'react';

// Mock data: Replace this with actual data fetching from your backend.
const mockProfiles = [
    { id: 1, name: 'John Doe', affiliateLinks: ['https://example.com/job1', 'https://example.com/job2'] },
    { id: 2, name: 'Jane Smith', affiliateLinks: ['https://example.com/job3'] },
];

const AffiliateProfileLinks = () => {
    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
        // Fetch profiles and their affiliate links from the backend
        // Replace this mock data with actual API/Firebase call
        setProfiles(mockProfiles);
    }, []);

    return (
        <div className="widget">
            <h3>Profiles Sharing Your Links</h3>
            <table>
                <thead>
                    <tr>
                        <th>Profile Name</th>
                        <th>Affiliate Links</th>
                    </tr>
                </thead>
                <tbody>
                    {profiles.map((profile) => (
                        <tr key={profile.id}>
                            <td>{profile.name}</td>
                            <td>
                                <ul>
                                    {profile.affiliateLinks.map((link, index) => (
                                        <li key={index}>
                                            <a href={link} target="_blank" rel="noopener noreferrer">
                                                {link}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AffiliateProfileLinks;
