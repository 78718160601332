import React, { useEffect, useState, useCallback } from "react";
// import firebase from "firebase/compat/app";
import { db} from "../firebase-config";
import { Button, Container, Row, Col, ListGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import { JobService, ProfileService, CompanyService } from "../services";
import "./JobDetails.css";
import { AppBackgrounds } from "../assets/background/background";
import firebase from "firebase/compat/app"; // Import Firebase
import { functions } from "../firebase-config";

const JobDetailsRecruiter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobDetails, setDetails] = useState();
  const [seekers, setSeekers] = useState([]);




  const getJobDetails = useCallback(async () => {
    const jobId = location.pathname.split("/").pop();
    try {
      const res = await JobService.getJobDetails(jobId);
      const details = res.data();
      const applicantsSnapshot = await res.ref.collection("applicants").get();
      const seekersPromises = applicantsSnapshot.docs.map(async (applicantDoc) => {
        const applicantData = applicantDoc.data();
        const profile = await ProfileService.getProfileDetails(applicantDoc.id);
        return { ...applicantData, ...profile.data() };
      });
      const s = await Promise.all(seekersPromises);
      setDetails({
        ...details,
        jobId: jobId,
        applicants: applicantsSnapshot.docs.map((x) => x.data()),
      });
      setSeekers(s);
    } catch (error) {
      console.log(error);
      Swal.fire({ text: "Error while fetching Job Details" });
    }
  }, [location.pathname]); // Memoize with the required dependencies
  
  useEffect(() => {
    getJobDetails();
  }, [getJobDetails]); // Add the memoized function to the dependency array
  
  


  const closeReq = async () => {
    try {
      await JobService.closeJob(jobDetails.jobId); // Remove the unused 'res'
      setDetails({ ...jobDetails, status: "closed" });
      Swal.fire({ text: "Requirement Closed" });
      await getJobDetails(); // Call getJobDetails after closing the job
    } catch (error) {
      Swal.fire({ text: "Error while closing requirement" });
    }
  };
  

  const selectSeeker = async (id) => {
    try {
      console.log('ID (clickedBy) in selectSeeker:', id);
    
      // Select the seeker by updating Firestore
      await JobService.selectSeeker(jobDetails.jobId, id);
    
      // Fetch the user's email from the profile collection
      const profileRef = db.collection('profile').doc(id);
      const profileDoc = await profileRef.get();
    
      if (!profileDoc.exists) {
        throw new Error("User profile not found");
      }
    
      const userEmail = profileDoc.data().email;
    
      // Use jobDetails to get the recruiter/company details
      const companyId = jobDetails.recruiter;
      const companyDoc = await CompanyService.getCompanyDetails(companyId);
    
      if (!companyDoc.exists) {
        throw new Error("Company not found");
      }
    
      const companyEmail = companyDoc.data().email;
      const company = companyDoc.data().name;
    
      // Send the email notification to the applicant
      const emailContent = {
        to: userEmail,
        jobDetails: {
          title: jobDetails.title,
          location: jobDetails.state,
          jobId: jobDetails.jobId,
        },
        email: companyEmail,
        company,
      };
    
      const sendEmailFunction = functions.httpsCallable('sendSelectionEmailV3');
      await sendEmailFunction(emailContent);
    
      // Look for the affiliate click related to this candidate and job
      const affiliateClickRef = db.collection('companies')
        .doc(companyId)
        .collection('jobAffiliateLinks')
        .doc(jobDetails.jobId)
        .collection('affiliateClicks')
        .where('clickedBy', '==', id); // Find clicks by this candidate
    
      const clickSnapshot = await affiliateClickRef.get();
    
      if (!clickSnapshot.empty) {
        // If the candidate was referred by an affiliate, update the affiliate signups
        const clickData = clickSnapshot.docs[0].data(); // Assuming one click per user for the job
        const affiliateId = clickData.affiliateId;
    
        // Update the jobAffiliateLinks for this job
        const affiliateRef = db.collection('companies')
          .doc(companyId)
          .collection('jobAffiliateLinks')
          .where('affiliateId', '==', affiliateId)
          .limit(1); // Get the first matching affiliate link
    
        const affiliateSnapshot = await affiliateRef.get();
    
        if (!affiliateSnapshot.empty) {
          const affiliateDoc = affiliateSnapshot.docs[0];
          await affiliateDoc.ref.update({
            signups: firebase.firestore.FieldValue.increment(1), // Increment the signup count
          });
        }
      }
    
      Swal.fire({ text: "Applicant notified by email and affiliate signup updated." });
    
    } catch (error) {
      console.error("Error while selecting candidate or sending email:", error.message || error);
      Swal.fire({ text: "Error while selecting candidate or sending email." });
    }
  };
  
  
  
  
  
  

  return (
    <>
      <Container style={{ fontFamily: "MontSerrat" }}>
        <Row>
          <Col md={6}>
            <h2 style={{ fontWeight: "bold" }}>{jobDetails?.title}</h2>
            <div
  style={{
    borderRadius: "10px",
    boxShadow: "5px 5px 20px #0005",
    overflow: "hidden",
    width: '100%',  // Ensures full width
    height: '300px', // Sets the fixed height for the container
    maxHeight: "300px", // Ensures the container height doesn't exceed 300px
  }}
>
  <img
    src={`https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/jobs%2F${jobDetails?.jobId}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`}
    alt = ""
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = AppBackgrounds.bg_1;
    }}
    style={{
      width: "100%",
      height: "100%", // Makes sure the image covers the entire container
      objectFit: "cover", // Ensures the image fills the container while maintaining its aspect ratio
      objectPosition: "center", // Centers the image within the container
    }}
  />
</div>

            <div className="my-3"></div>
          </Col>
          <Col md={6}>
            <h3>Job Description</h3>
            <hr />
            <p>{jobDetails?.description}</p>
            <div className="d-grid">
              {jobDetails?.status === "active" && (
                <Button variant="danger" onClick={closeReq}>
                  Close Requirement
                </Button>
              )}
              {jobDetails?.status === "closed" && <Button>Closed </Button>}
            </div>
          </Col>
        </Row>
        <Row>
          <h3>Applied Candidates</h3>
          <hr />
        {seekers.map((item) => (
            <Col md={6} className="my-2 mb-5">
              <div
                className="profile-chip"
                style={item.selected ? { backgroundColor: "#40D06530" } : {}}
              >
                <div className="avatar">
                  <img src={item.avatar} alt = ""/>
                </div>
                <div className="name">
                  <h6>{item.name}</h6>
                  <p>{item.email}</p>
                </div>
              </div>
              <div className="d-grid">
                <div className="btn-group " role="group">
                  {jobDetails.status === "active" ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => selectSeeker(item.id)}
                    >
                      Select Candidate
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate("/profiles/" + item.id)}
                  >
                    View Profile
                  </button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {/* <div
              style={{
                
                // display:"flex",
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                overflow: "hidden",
                padding: "20px",
                
                // marginLeft:"25px",
                backgroundColor:"white"
              }}
            > */}
        <Row className="justify-content-center">
          <Col md={12}>
            <div
              style={{
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                minWidth: "316px",
                padding: "20px",
                margin: "40px",
                marginTop: "20px",
                marginRight: "none",
                backgroundColor: "#F6F6F6",
              }}
            >
              <h3>Brief Details</h3>
              <hr />
              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Created Date
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {jobDetails?.creationTime?.split("T")[0]}
                </ListGroup.Item>
              </ListGroup>
              <ListGroup as="ul" horizontal className="list-group">
                <ListGroup.Item as="li" className="list-group-left">
                  Status
                </ListGroup.Item>
                <ListGroup.Item as="li" className="list-group-right">
                  {" "}
                  {jobDetails?.status}
                </ListGroup.Item>
              </ListGroup>

              {/* <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Company Size
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.companySize}
                </ListGroup.Item>
              </ListGroup> */}

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Role
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.classification}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
  <ListGroup.Item className="list-group-left">
    Location
  </ListGroup.Item>
  <ListGroup.Item className="list-group-right">
    {jobDetails?.city && jobDetails?.state 
      ? `${jobDetails.city}, ${jobDetails.state}` 
      : 'Location not specified'}
  </ListGroup.Item>
</ListGroup>


              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Hours
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.jobHours}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Payroll Category?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.payrollCategory}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Salary Range / Hr
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.salaryRange}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Job Type
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.type}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Teletherapy Platform Used
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.telepathyPlatform}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Benefits
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.benefits}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Benefits Details
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.benefitDetails}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  PSLF Eligible?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.pslf}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  PSLF Details
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.pslfDetails}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Is Direct Hire
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.isDirectHire}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Is Indirect Time Paid?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.isIndirectTimePaid}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Details of Paid Indirect time
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.inDirectTimePaidDetails}
                </ListGroup.Item>
              </ListGroup>

              {/* <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Title
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.title}
                </ListGroup.Item>
              </ListGroup> */}

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Population Served
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.populationServed.join(", ")}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Therapy Ind/Group Sizes
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.directTherapyStudents.join(", ")}{" "}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Caseload Management
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.caseloadMgmt}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Caseload Size
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.slpStudents}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  How Many sites?
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.sitesToCover}
                </ListGroup.Item>
              </ListGroup>

              {/* <ListGroup horizontal className = "list-group">
              <ListGroup.Item className = "list-group-left">Tax Classification
              </ListGroup.Item>
              <ListGroup.Item className = "list-group-right"> {jobDetails?.taxClassification}
              </ListGroup.Item>
              </ListGroup> */}
            </div>
          </Col>
          {/* <Col md={4}>
          <div
              style={{
                
                
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                overflow: "hidden",
                padding: "20px",
                margin: "40px",
                minWidth: "316px",
                // marginLeft:"25px",
                backgroundColor:"white"
              }}
            >
            <h3>Hot Job#1</h3>
            <hr />

            <ListGroup horizontal className = "list-group">
              <ListGroup.Item className = "list-group-left">Title
              </ListGroup.Item>
              <ListGroup.Item className = "list-group-right"> {jobDetails?.title}
              </ListGroup.Item>
              </ListGroup>
         
              </div>        
           </Col> */}
        </Row>
        <Row>
          <Col md={12}>
            <div
              style={{
                borderRadius: "30px",
                boxShadow: "5px 5px 20px #0003",
                overflow: "hidden",
                padding: "20px",
                margin: "40px",
                marginTop: "20px",
                minWidth: "316px",
                // marginLeft:"25px",
                backgroundColor: "#f6f6f6",
              }}
            >
              <h3>Hot Job#2</h3>
              <hr />

              <ListGroup horizontal className="list-group">
                <ListGroup.Item className="list-group-left">
                  Title
                </ListGroup.Item>
                <ListGroup.Item className="list-group-right">
                  {" "}
                  {jobDetails?.title}
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </Container>
    </>
  );
};

// const InfoCard = (props) => {
//   return (
//     <div
//       style={{
//         borderBottom: "1px solid #AAA3",
//         padding: "5px",
//         marginBottom: "10px",
//         fontSize: "20px",
//         display: "flex",
//       }}
//     >
//       <div style={{ width: "35%" }}>{props?.label}</div>
//       <div style={{ width: "65%" }}> {props.value}</div>
//     </div>
//   );
// };

export default JobDetailsRecruiter;
