import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db } from "../../firebase-config"; // Firestore config
import { Card, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@mui/material';

const AffiliateManagement = () => {
  const companyUser = useSelector((state) => state.authReducer.user);
  const [jobAffiliates, setJobAffiliates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (companyUser && companyUser.id) {
      const fetchAffiliateData = async () => {
        try {
          const jobAffiliateLinksRef = db
            .collection('companies')
            .doc(companyUser.id)
            .collection('jobAffiliateLinks');

          const jobSnapshot = await jobAffiliateLinksRef.get();
          const jobAffiliatesData = await Promise.all(jobSnapshot.docs.map(async (jobDoc) => {
            const jobAffiliateLinkId = jobDoc.id; // JobAffiliateLink ID
            const jobData = jobDoc.data();

            // Fetch Total Clicks directly from the JobAffiliateLink document
            const totalClicks = jobData.totalClicks || 0; // Fetching Total Clicks

            // Fetch the affiliate links associated with this JobAffiliateLink ID
            const affiliateLinksSnapshot = await jobAffiliateLinksRef
              .doc(jobAffiliateLinkId)
              .collection('affiliateLinks') // Fetching from the correct subcollection
              .get();

            // Collect relevant affiliate link data if available
            const affiliateData = affiliateLinksSnapshot.docs.map(affiliateDoc => {
              const data = affiliateDoc.data();
              return {
                affiliateId: data.affiliateId || 'None',
                actionType: data.actionType || 'N/A',
                commission: data.commission || 'N/A',
                createdAt: data.createdAt ? new Date(data.createdAt.seconds * 1000).toLocaleString() : 'N/A',
                link: data.link || 'N/A',
              };
            });

            // Return combined data
            return {
              jobAffiliateLinkId, // The JobAffiliateLink ID
              totalClicks, // Total clicks for this JobAffiliateLink
              affiliateData, // Store fetched affiliate data
            };
          }));

          setJobAffiliates(jobAffiliatesData);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching affiliates:', error);
          setErrorMessage('Failed to fetch affiliate data.');
          setLoading(false);
        }
      };

      fetchAffiliateData();
    } else {
      setErrorMessage('Company user or ID not found.');
      setLoading(false);
    }
  }, [companyUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errorMessage) {
    return <div style={{ color: 'red' }}>{errorMessage}</div>;
  }

  return (
    <div className="full-width-container affiliate-management">
      <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '30px', color: 'gray' }}>
        Affiliate Management
      </Typography>

      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: 'space-around' }}>
        {/* Job Links Card */}
        <Card style={{ width: '100%', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
          <Typography variant="h6" style={{ color: 'gray', marginBottom: '10px' }}>
            Job Affiliate Links
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>JobAffiliateLink ID</TableCell>
                  <TableCell>Total Clicks</TableCell>
                  <TableCell>Affiliate ID</TableCell>
                  <TableCell>Action Type</TableCell>
                  <TableCell>Commission</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobAffiliates.length > 0 ? (
                  jobAffiliates.map((affiliate, index) => (
                    <TableRow key={index}>
                      <TableCell>{affiliate.jobAffiliateLinkId}</TableCell> {/* JobAffiliateLink ID */}
                      <TableCell>{affiliate.totalClicks}</TableCell> {/* Total Clicks */}
                      <TableCell>{affiliate.affiliateData[0]?.affiliateId || 'None'}</TableCell> {/* Affiliate ID */}
                      <TableCell>{affiliate.affiliateData[0]?.actionType || 'N/A'}</TableCell> {/* Action Type */}
                      <TableCell>{affiliate.affiliateData[0]?.commission || 'N/A'}</TableCell> {/* Commission */}
                      <TableCell>{affiliate.affiliateData[0]?.createdAt || 'N/A'}</TableCell> {/* Created At */}
                      <TableCell>
                        <a href={affiliate.affiliateData[0]?.link || '#'} target="_blank" rel="noopener noreferrer">
                          {affiliate.affiliateData[0]?.link || 'N/A'}
                        </a> {/* Link */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>No Job Affiliate Links found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </div>
  );
};

export default AffiliateManagement;
