import React, { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from "../../firebase-config"; // Import your Firebase config
import firebase from "firebase/compat/app"; // Import Firebase app
import "firebase/compat/firestore"; // Import Firestore

const RouteHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Function to log the affiliate click
  const logAffiliateClick = useCallback(async (companyId, affiliateId, jobAffiliateLinkId) => {
    try {
      const affiliateClicksRef = db
        .collection('companies')
        .doc(companyId)
        .collection('jobAffiliateLinks')
        .doc(jobAffiliateLinkId) // Using JobAffiliateLink ID
        .collection('affiliateClicks');

      // Log the click in the affiliateClicks subcollection
      await affiliateClicksRef.add({
        affiliateId,
        clickedAt: new Date(),
      });

      console.log("Affiliate click logged successfully.");

      // Now increment totalClicks directly in the JobAffiliateLink document
      await incrementTotalClicks(companyId, jobAffiliateLinkId);
    } catch (error) {
      console.error("Error logging affiliate click:", error.message || error);
    }
  }, []); // No dependencies

  // Function to increment total clicks
  const incrementTotalClicks = async (companyId, jobAffiliateLinkId) => {
    try {
      const jobAffiliateLinkRef = db
        .collection('companies')
        .doc(companyId)
        .collection('jobAffiliateLinks')
        .doc(jobAffiliateLinkId); // Using JobAffiliateLink ID

      // Increment the totalClicks field
      await jobAffiliateLinkRef.set({
        totalClicks: firebase.firestore.FieldValue.increment(1)
      }, { merge: true });

      console.log("Total clicks incremented successfully.");
    } catch (error) {
      console.error("Error incrementing total clicks:", error.message || error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const affiliateId = params.get('ref');
    const jobAffiliateLinkId = params.get('jobId'); // Using JobAffiliateLink ID
    const companyId = params.get('company'); // Get companyId from the URL

    const logClickAndRedirect = async () => {
      if (affiliateId && jobAffiliateLinkId && companyId) {
        try {
          await logAffiliateClick(companyId, affiliateId, jobAffiliateLinkId);
          console.log(`Logged click for affiliate ${affiliateId} on job affiliate link ${jobAffiliateLinkId}.`);
        } catch (error) {
          console.error("Failed to log affiliate click:", error.message || error);
        }
      }

      // Redirect to the job details page
      navigate(`/manage-jobs/details/${jobAffiliateLinkId}`); // Navigate using the JobAffiliateLink ID
    };

    logClickAndRedirect();
  }, [location.search, navigate, logAffiliateClick]); // Added logAffiliateClick to dependencies

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default RouteHandler;
