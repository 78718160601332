import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { DashCard } from "../components";
import { DashIcons } from "../assets/icons/dashIcons";
import ReactApexChart from "react-apexcharts";
import QuoteCard from "../components/QuoteCard";
import { JobService } from "../services/Jobs";
// import { ProfileService } from "../services/Profile";
import UserNameWrap from "../components/UserNameWrap";
import { AltJobService } from "../services/AltJobs";
import firebase from "firebase/compat/app";
import {  useSelector } from "react-redux";
import "./Dashboard.css";

import "firebase/auth"; 


const DashboardPage = () => {

  

  
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);  // State to store userId
  const [displayName, setDisplayName] = useState(null);  // State to store displayName
  console.log(displayName, 'dizzyname')
  const [loading, setLoading] = useState(true);  // State for loading
  const isCompany = useSelector((state) => state.authReducer.isCompany); // Fetch isCompany from Redux



  // const userName = firebase.auth().currentUser.displayName;
  // console.log(userName)





  // States to store graph data and user axis selections
  const [graphData, setGraphData] = useState({
    series: [{ name: "Jobs", data: [] }],
    options: {
      chart: { height: 350, type: "area" },
      xaxis: { categories: [] },
    },
  });
  const [pieData, setPieData] = useState({
    series: [0, 0],  // Job count and AltJob count
    options: { chart: { type: "donut" } },
  });
  console.log(pieData, 'pieData')

  const [xAxisOption, setXAxisOption] = useState("title");  // Default x-axis option
  const [yAxisOption, setYAxisOption] = useState("applicants");  // Default y-axis option

  useEffect(() => {
    // Firebase auth listener to detect user state change
    const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setUserId(firebaseUser.uid);  // Set userId in state
        setDisplayName(firebaseUser.displayName);  
        console.log(firebaseUser.displayName, 'dpd')// Set displayName in state
        setLoading(false);  // Stop loading once user is found
      } else {
        setLoading(false);  // Stop loading
      }
    });
    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   if (!loading && userId) {
  //     navigate("/dashboard", { replace: true });  // Ensure the app waits until loading finishes
  //   }
  // }, [loading, userId, navigate]);
  
  
 /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (userId) {
      fetchJobsData(userId);
      fetchAltJobsData(userId);
    }
  }, [userId]);
/* eslint-enable react-hooks/exhaustive-deps */

  const fetchJobsData = async (userId) => {
    try {
      const res = await JobService.getJobs(userId, 6, null);  // Fetch jobs for the user
      const jobsList = res.docs.map((doc) => doc.data());

      // Use the x-axis and y-axis selections to update graph data
      const categories = jobsList.map((job) => job[xAxisOption]);  // x-axis data based on user selection
      const yData = jobsList.map((job) => (yAxisOption === "applicants" ? Object.keys(job.applicants).length : job[yAxisOption]));  // y-axis data

      setGraphData({
        series: [{ name: "Jobs", data: yData }],
        options: { ...graphData.options, xaxis: { categories } },
      });

      setPieData((prevData) => ({
        ...prevData,
        series: [yData.length, prevData.series[1]],  // Update job count in pie chart
      }));
    } catch (error) {
      console.error("Error fetching jobs data:", error);
    }
  };

  const fetchAltJobsData = async (userId) => {
    try {
      const res = await AltJobService.getAllJobs(userId, 6, null);  // Fetch altjobs for the user
      const altJobsList = res.docs.map((doc) => doc.data());

      setPieData((prevData) => ({
        ...prevData,
        series: [prevData.series[0], altJobsList.length],  // Update altjob count in pie chart
      }));
    } catch (error) {
      console.error("Error fetching altjobs data:", error);
    }
  };

  const handleAxisChange = () => {
    // Re-fetch job data to update the chart based on new x/y selections
    if (userId) fetchJobsData(userId);
  };

  // If the page is still loading (waiting for Firebase to determine auth state)
  if (loading) {
    return <div>Loading...</div>;  // Or use a loading spinner
  }

  return (
    <UserNameWrap>
    <Container>
    <Row className="my-3">
      {userId && (
        <Col md={3} className="d-flex justify-content-center">
          <QuoteCard userId={userId} />
        </Col>
      )}

      {/* Profile Card - always visible */}
      <Col md={isCompany ? 3 : 4} className="d-flex justify-content-center">
        <DashCard
          icon={DashIcons.users}
          title={"Profile"}
          subTitle={"Profile Settings"}
          onClick={() => navigate(isCompany ? "/company-details" : "/profile")}
          className="dash-card"
        />
      </Col>

      {/* Job Poster Card - only visible for companies */}
      {isCompany && (
        <Col md={3} className="d-flex justify-content-center">
          <DashCard
            icon={DashIcons.message}
            title={"Job Dashboard"}
            subTitle={"Manage Jobs"}
            onClick={() => navigate("/manage-jobs")}
            className="dash-card"
          />
        </Col>
      )}

      {/* Applied Jobs Card - adjusts size based on isCompany */}
      {!isCompany && (
	     <Col md={3} className="d-flex justify-content-center">

        <DashCard
          icon={DashIcons.message}
          title={"Applied Jobs"}
          subTitle={"Jobs"}
          onClick={() => navigate("/manage-jobs/your-applications")}
          className="dash-card"
         />
        </Col>
      )}
      </Row>
  
  


      <Row>
        <Col md={12}>
          <Form>
            <Row className="align-items-center">
              <Col md={4}>
                <Form.Group controlId="xAxisSelect">
                  <Form.Label>X-Axis</Form.Label>
                  <Form.Select
                    value={xAxisOption}
                    onChange={(e) => setXAxisOption(e.target.value)}
                  >
                    <option value="title">Job Title</option>
                    <option value="createdAt">Creation Date</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="yAxisSelect">
                  <Form.Label>Y-Axis</Form.Label>
                  <Form.Select
                    value={yAxisOption}
                    onChange={(e) => setYAxisOption(e.target.value)}
                  >
                    <option value="applicants">Number of Applicants</option>
                    <option value="salary">Salary</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={4} className="pt-4">
                <Button variant="primary" onClick={handleAxisChange}>
                  Update Graph
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row className="my-5">
        <Col md={8}>
          <div className="box">
            <ReactApexChart
              options={graphData.options}
              series={graphData.series}
              type="area"
              height={350}
            />
          </div>
        </Col>
      </Row>
    </Container>
  </UserNameWrap>
  );
};

export default DashboardPage;