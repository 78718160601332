// import { getFunctions, httpsCallable } from "firebase/functions";
// import { db } from "../firebase-config";
// import { CompanyService } from "./Company"; 
   
 

// Function to create Stripe Connect account and handle onboarding
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../firebase-config";
import { CompanyService } from "./Company"; 
import { ProfileService } from "./Profile"; // Assume you have a ProfileService

// Function to create Stripe Connect account and handle onboarding
export const createStripeAccount = async (email, userType) => {
  const functions = getFunctions();
  const createAccount = httpsCallable(functions, 'createStripeConnectAccount');
  try {
    const { data } = await createAccount({ email, userType });
    return data; // Return data including accountId and accountLink
  } catch (error) {
    throw new Error(`Error creating Stripe Connect account: ${error.message}`);
  }
};

// Function to check if a Stripe Connect account exists and create one if not
export const checkStripeConnect = async (userId, userType) => {
  try {
    let userDoc;
    if (userType === 'company') {
      userDoc = await CompanyService.getCompanyDetails(userId); 
      console.log("Company document:", userDoc); // Add logging to see what you're getting
    } else {
      userDoc = await ProfileService.getProfileDetails(userId); 
      console.log("Profile document:", userDoc); // Add logging to see what you're getting
    }

    if (!userDoc.exists) {
      console.error(`Document for user ${userId} does not exist.`);
      return { hasStripeConnect: false, onboardingLink: null };
    }

    const userData = userDoc.data();

    if (!userData || !userData.email) {
      console.error("Email is missing from the user document.");
      return { hasStripeConnect: false, onboardingLink: null };
    }

    const userEmail = userData.email;

    if (userData.stripeConnectId) {
      // User already has a Stripe Connect ID
      return { hasStripeConnect: true, onboardingLink: null };
    } else {
      // Create Stripe Connect account and get onboarding link
      const { accountId, accountLink } = await createStripeAccount(userEmail, userType);

      // Save Stripe Connect ID in Firestore
      await db.collection(userType === 'company' ? "companies" : "profiles").doc(userId).update({
        stripeConnectId: accountId,
      });

      // Return the onboarding link
      return { hasStripeConnect: false, onboardingLink: accountLink };
    }
  } catch (error) {
    console.error("Error checking Stripe Connect:", error);
    throw new Error("Error checking Stripe Connect");
  }
};


  