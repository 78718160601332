import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { db} from "../firebase-config";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import {
  CompanyService,
  FirebaseAuthService,
  ProfileService,
} from "../services";
import Swal from "sweetalert2";
import { Checkbox } from "@mui/material";
// import firebase from "firebase/compat/app";



const SignUpPage = () => {
  const emailInput = useRef(null);
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    emailId: "",
    name: "",
    password: "",
    rePassword: "",
    isCompany: false,
  });

  const [error, setError] = useState({
    email: "",
    password: "",
    name: "",
    credentials: "",
  });

  const isValid = () => {
    let flag = true;
    let errorData = {};
    if (credentials.emailId === "") {
      flag = false;
      errorData.email = "Email cannot be blank";
    }
    if (credentials.name === "") {
      flag = false;
      errorData.name = "Name cannot be blank";
    }
    if (credentials.password === "") {
      flag = false;
      errorData.password = "Password cannot be blank";
    }
    if (credentials.password !== credentials.rePassword) {
      flag = false;
      errorData.password = "Passwords do not match";
    }
    setError(errorData);
    return flag;
  };

  const onSignup = async () => {
    if (isValid()) {
      try {
        const res = await FirebaseAuthService.handleEmailSignup(
          credentials.emailId,
          credentials.password
        );
        FirebaseAuthService.verifyEmail(res.user);

        if (res.user) {
          Swal.fire("Verification mail has been sent to your mail id");
        }
        createProfile(
          res,
          credentials.name,
          credentials.emailId,
          "",
          "",
          credentials.isCompany,
          'email'
        );
        console.log(res.user.uid, 'ressy user')
        await handleUserRegistration(res.user.uid);
      } catch (error) {
        Swal.fire("Failed to create account. ", error.message);
      }
    }
  };
  // const uidVal = firebase.auth().currentUser.uid;
  const createProfile = (res, name, email, avatar, isCompany, method) => {
    if (res.user) {
      if (isCompany) {
        CompanyService.createCompany(res.user.uid, name, email, avatar);
        

      } else {
        ProfileService.createProfile(res.user.uid, name, email, avatar);
        


      }
      // Check if the method is 'email' or social login and show appropriate alert
    if (method === 'email') {
      Swal.fire("Verification mail has been sent to your email ID.");
    } else {
      Swal.fire("Verification mail has been sent to your email ID. Then please login with your email.");
    }
      console.log(res.user.uid, "res uid")
      // console.log(uidVal, "uid val")
      navigate("/login");
      
    } else {
      Swal.fire("Failed to signup", "Something went wrong");
    }
  };

  // Affiliate Registration Handling
 // Affiliate Registration Handling
const handleUserRegistration = async (userId) => {
  try {
    // Retrieve the stored affiliate click details from localStorage
    const affiliateClickId = localStorage.getItem('affiliateClickId');
    const affiliateId = localStorage.getItem('affiliateId');
    const jobId = localStorage.getItem('jobId');
    const companyId = localStorage.getItem('companyId');

    if (affiliateClickId && affiliateId && jobId && companyId) {
      // Reference the specific affiliate click under the correct job and company
      const clickRef = db.collection('companies')
        .doc(companyId)
        .collection('jobAffiliateLinks')
        .doc(jobId)
        .collection('affiliateClicks')
        .doc(affiliateClickId);

      // Update the clickedBy field with the user's ID
      await clickRef.update({
        clickedBy: userId,  // Update with the registered user's ID
      });

      // Optionally clear the stored affiliate details from localStorage
      localStorage.removeItem('affiliateClickId');
      localStorage.removeItem('affiliateId');
      localStorage.removeItem('jobId');
      localStorage.removeItem('companyId');
    }
  } catch (error) {
    console.error("Error updating affiliate click with user ID:", error);
  }
};

  
  
  
  
  
  

  const onGoogle = async () => {
    try {
      
      const res = await FirebaseAuthService.handleGoogleLogin();
      
// const user = auth.currentUser;
      if (res.user) {
        const userProfile = await ProfileService.getProfileDetails(
          res.user.uid
        );
        const companyProfile = await CompanyService.getCompanyDetails(
          res.user.uid
        );
        if (userProfile.exists || companyProfile.exists ) {
          
          Swal.fire("Profile Already exists - pls login");
          navigate("/login");
        return;}
        else {
      createProfile(
        res,
        res.user.displayName,
        res.user.email,
        // res.user.phoneNumber,
        res.user.photoURL,
        credentials.isCompany,
        'google'
      );
      
       // Handle affiliate click update after registration
       await handleUserRegistration(res.user.uid);
      
    } }}catch (error) {
      Swal.fire("Failed to create account. ", error.message);
    }
  };

  const onFacebook = async () => {
    try {
      const res = await FirebaseAuthService.handleFacebookLogin();
      
      if (!res.user) return;  // Exit if there is no user
  
      // Fetch user and company profiles
      const [userProfile, companyProfile] = await Promise.all([
        ProfileService.getProfileDetails(res.user.uid),
        CompanyService.getCompanyDetails(res.user.uid)
      ]);
  
      // If either profile exists, log and return
      if (userProfile.exists || companyProfile.exists) {
        console.log("User or company profile already exists.");
        return;  // Exit early since profile already exists
      }
  
      // If no profile exists, proceed to create the profile
      await createProfile(
        res,
        res.user.displayName,
        res.user.email,
        // res.user.phoneNumber,
        res.user.photoURL,
        credentials.isCompany,
        'facebook'
      );
       // Handle affiliate click update after registration
       await handleUserRegistration(res.user.uid);
      
      console.log("Profile created successfully.");
      
    } catch (error) {
      console.error("Failed to create account:", error.message);
    }
  };
  
// Scroll up function
// const scrollToTop = () => {
//   if (topOfPageRef && topOfPageRef.current) {
//     topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
//   }
// };
const topOfPageRef = useRef(null); // Reference to the location you want to scroll to
useEffect(() => {
  if (topOfPageRef.current) {
    topOfPageRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the ref location
  }
}, []); // Empty dependency array ensures this runs on page load


  useEffect(() => {
    setTimeout(() => {
      if (emailInput && emailInput.current) emailInput.current.focus();
    }, 1000);
  }, []);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {" "}
      <section className="hero-section">
        <div className="hero-wrap" style={{ backgroundPositionY: 0.7 * scrollY }}>
          <div className="overlay"></div>
          <div className="hero-text">
            <div  className="text-center col-lg-8">
              {/* <h1>Create your new account </h1>
              <h3 className="py-3">Signup </h3> */}
            </div>
          </div>
        </div>
        
      </section >
      <div ref={topOfPageRef}  className="full-page d-flex align-items-center justify-content-center">
        <div   style = {{marginTop:"100px"}}className="login-container">
          <h3 className="my-2">1StopSPOT</h3>
          

          <p className="my-2">
  <span role="img" aria-label="check">✅</span> If you Want to POST JOBS then REGISTER as a COMPANY
</p>
<p className="my-2">
  <span role="img" aria-label="check">✅</span> If you Want your Job Automatically Posted to FB then Register with FB
</p>
<p className="my-2">
  <span role="img" aria-label="check">✅</span> If you Want to Apply to Jobs and Hang out then just Register
</p>
<p className="my-2">
  <span role="img" aria-label="heart">❤️</span> This is a site built from the ground up by an SLP. <span role="img" aria-label="love">❤️</span> 
</p>

          <div className="d-flex  my-3">
          <div className="company-checkboxes">
  {/* Register as a company */}
  <div className="company-checkbox">
  <Checkbox
    checked={credentials.isCompany} // Only true when it's a company
    onChange={() => {
      setCredentials({
        ...credentials,
        isCompany: true, // Set to true when company is selected
      });
    }}
  />
  <div style={{ display: "inline-block" }}> {/* Ensure inline-block for the text to sit next to the checkbox */}
    <span>Register as a Company to Create and Post Jobs</span>
    <br /> {/* Line break to ensure the smaller text is under the main text */}
    {/* <span style={{ fontSize: "smaller", fontStyle: "italic" }}>
      "A Company can also Apply to Jobs"
    </span> */}
  </div>
</div>



{/* Register as a profile (non-company) */}
<div className="company-checkbox">
  <Checkbox
    checked={!credentials.isCompany} // Only true when it's not a company
    onChange={() => {
      setCredentials({
        ...credentials,
        isCompany: false, // Set to false when profile is selected
      });
    }}
  />
  <span>Register to only Apply to Jobs</span>
</div>

</div>

            </div>
          <div className="login-form">
            <FloatingLabel
              controlId="name"
              label="Full Name"
              className="login-input mb-2"
            >
              <Form.Control
                ref={emailInput}
                placeholder="Full Name"
                value={credentials.name}
                onChange={(e) => {
                  setCredentials({ ...credentials, name: e.target.value });
                }}
              />
              {error.name && <p className="error-input">{error.name}</p>}
            </FloatingLabel>
            <FloatingLabel
              controlId="email"
              label="Email address"
              className="login-input mb-2"
            >
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={credentials.emailId}
                onChange={(e) => {
                  setCredentials({ ...credentials, emailId: e.target.value });
                }}
              />
              {error.email && <p className="error-input">{error.email}</p>}
            </FloatingLabel>
            <FloatingLabel
              controlId="password"
              label="Create Password"
              className="login-input mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Password"
                value={credentials.password}
                onChange={(e) => {
                setCredentials({ ...credentials, password: e.target.value });
                }}
              />
              {error.password && (
                <p className="error-input">{error.password}</p>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="repassword"
              label="Re Enter Password"
              className="login-input mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Password"
                value={credentials.rePassword}
                onChange={(e) => {
                  setCredentials({
                    ...credentials,
                    rePassword: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onSignup();
                }}
              />
              {error.password && (
                <p className="error-input">{error.password}</p>
              )}
            </FloatingLabel>
            {/* <div className="d-flex justify-content-center my-3">
              <div className="company-checkbox">
                <Checkbox
                  checked={credentials.isCompany}
                  onChange={() => {
                    setCredentials({
                      ...credentials,
                      isCompany: !credentials.isCompany,
                    });
                  }}
                />
                <span>Register as a company</span>
              </div>
            </div> */}
            <div className="d-grid my-3">
              {error.credentials && (
                <p className="error-input">{error.credentials}</p>
              )}
              <Button onClick={onSignup}>Create Account</Button>
              <p className="text-center m-2">Or</p>
              <Button
                className="my-2"
                variant="outline-primary"
                onClick={onGoogle}
              >
                Signup with Google
              </Button>
              <Button className="btn-fb my-2" onClick={onFacebook}>
                Signup with Facebook
              </Button>

              <p className="my-3 text-center">
                Already have an account?{" "}
                <span
                  className="text-primary btn-link"
                  onClick={() => navigate("/login")}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 py-5"></div>
    </>
  );
};
export default SignUpPage;