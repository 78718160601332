import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { AltJobService, JobService } from "../services";
import { useSelector } from "react-redux";
import { JobCard } from "../pages/JobsDashboard"; 

// Spinner Component
const Spinner = () => (
  <div className="d-flex justify-content-center my-3">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);


// JobService.logJobsAndApplicants();

// const totalJObs = JobService.getTotalJobs
// console.log(totalJObs, "snapshot Total JObs")
// ErrorMessage Component
const ErrorMessage = ({ message }) => (
  <div className="no-jobs-message">
    <p>{message}</p>
  </div>
);

// Pagination Component
const PaginationComponent = ({ page, totalPages, onPageChange, loading }) => {
  if (totalPages < 1) return null;
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ paddingTop: "20px" }}>
      <Pagination className="my-2 align-items-center">
        <Pagination.First onClick={() => onPageChange(1)} disabled={page === 1 || loading} />
        <Pagination.Prev onClick={() => onPageChange(page - 1)} disabled={page === 1 || loading} />
        {[...Array(totalPages)].map((_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === page} onClick={() => onPageChange(i + 1)} disabled={loading}>
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => onPageChange(page + 1)} disabled={page === totalPages || loading} />
        <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={page === totalPages || loading} />
      </Pagination>
    </div>
  );
};

const JobApplicationSeeker = () => {
  const [regJobs, setJobs] = useState([]); // Regular jobs
  const [ajobs, setAltJobs] = useState([]); // Alt jobs
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingAltJobs, setLoadingAltJobs] = useState(false);
  const [jobError, setJobError] = useState(null);
  const [altJobError, setAltJobError] = useState(null);
  const [pagination, setPagination] = useState({ page: 1, totalPages: 1 });
  const [altPagination, setAltPagination] = useState({ page: 1, totalPages: 1 });
  const itemsPerPage = 6;
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.authReducer.auth);

  // Fetch regular jobs
  // Fetch regular jobs
  // const getAllJobs = useCallback(async (page) => {
  //   try {
  //     setLoadingJobs(true);
  //     const reGjobs = await JobService.getAppliedJobs(userAuth.uid, "jobs"); // Fetch applied jobs
  //     setJobs(reGjobs);
  //     const totalJobs = reGjobs.length;
  //     console.log(totalJobs, "total reg applied Jobs");
  //     setPagination({ page, totalPages: Math.ceil(totalJobs / itemsPerPage) });
  //   } catch (error) {
  //     setJobError("Error while fetching applied regular jobs");
  //     Swal.fire({ text: "Error while fetching applied regular jobs" });
  //   } finally {
  //     setLoadingJobs(false);
  //   }
  // }, [userAuth.uid]);

  const getAllJobs = useCallback(async (page) => {
    try {
      setLoadingJobs(true);
      
      // Fetch applied jobs
      const reGjobs = await JobService.getAppliedJobs(userAuth.uid, "jobs");

      // For each job, fetch the applicants from the subcollection
      const jobsWithApplicants = await Promise.all(
        reGjobs.map(async (job) => {
          const applicantsSnapshot = await JobService.getApplicants(job.id); // Fetch the applicants for the job
          const applicants = applicantsSnapshot.docs.map(doc => doc.data()); // Extract applicant data
          
          // Return job details along with applicants count or data
          return { 
            ...job, 
            applicants, // Add applicants array to each job
            totalApplicants: applicants.length // Optionally add the total number of applicants
          };
        })
      );

      setJobs(jobsWithApplicants); // Set jobs with applicants data
      const totalJobs = jobsWithApplicants.length;
      console.log(totalJobs, "total reg applied Jobs");
      setPagination({ page, totalPages: Math.ceil(totalJobs / itemsPerPage) });

    } catch (error) {
      setJobError("Error while fetching applied regular jobs");
      Swal.fire({ text: "Error while fetching applied regular jobs" });
    } finally {
      setLoadingJobs(false);
    }
  }, [userAuth.uid]);

  

  // Fetch alt jobs
  // const getAllAltJobs = useCallback(async (page) => {
  //   try {
  //     setLoadingAltJobs(true);
  //     const ajobs = await AltJobService.getAppliedAltJobs(userAuth.uid); // Fetch applied alt jobs
  //     setAltJobs(ajobs);
  //     const totalAltJobs = ajobs.length;
  //     console.log(totalAltJobs, "total Alt applied Jobs");
  //     setAltPagination({ page, totalPages: Math.ceil(totalAltJobs / itemsPerPage) });
  //   } catch (error) {
  //     setAltJobError("Error while fetching applied alt jobs");
  //     Swal.fire({ text: "Error while fetching applied alt jobs" });
  //   } finally {
  //     setLoadingAltJobs(false);
  //   }
  // }, [userAuth.uid]);

  const getAllAltJobs = useCallback(async (page) => {
    try {
      setLoadingAltJobs(true);
  
      // Fetch applied alt jobs
      const ajobs = await AltJobService.getAppliedAltJobs(userAuth.uid);
  
      // Iterate through each alt job and fetch its applicants
      const altJobsWithApplicants = await Promise.all(
        ajobs.map(async (job) => {
          // Use the getApplicants function to fetch the applicants for alt jobs
          const applicantsSnapshot = await AltJobService.getApplicants(job.id); // Fetch the applicants for the alt job
          const applicants = applicantsSnapshot.docs.map(doc => doc.data()); // Extract applicant data
  
          return {
            ...job,
            applicants, // Add the applicants array to the alt job object
            totalApplicants: applicants.length, // Add the total number of applicants
          };
        })
      );
  
      // Set the jobs state with alt jobs including the applicants info
      setAltJobs(altJobsWithApplicants);
  
      const totalAltJobs = altJobsWithApplicants.length;
      console.log(totalAltJobs, "total Alt applied Jobs");
  
      // Set pagination details
      setAltPagination({ page, totalPages: Math.ceil(totalAltJobs / itemsPerPage) });
    } catch (error) {
      setAltJobError("Error while fetching applied alt jobs");
      Swal.fire({ text: "Error while fetching applied alt jobs" });
    } finally {
      setLoadingAltJobs(false);
    }
  }, [userAuth.uid]);
  
  
  
  
  

  

  useEffect(() => {
    getAllJobs(1);
    getAllAltJobs(1);
  }, [getAllJobs, getAllAltJobs]);

  const handlePageChange = (newPage) => {
    getAllJobs(newPage);
  };

  const handleAltPageChange = (newPage) => {
    getAllAltJobs(newPage);
  };

  return (
    <Container>
      {/* Regular Jobs Section */}
      <div className="job-container">
        <h3>Applied Jobs</h3>
        <Row>
          {loadingJobs ? (
            <Spinner />
          ) : jobError ? (
            <ErrorMessage message={jobError} />
          ) : regJobs.length === 0 ? (
            <ErrorMessage message="You have not applied to any job" />
          ) : (
            regJobs.map((regJob) => (
              <JobCard
              key={`reg-${regJob.id}`}
              job={regJob}
              totalApplicants={regJob.totalApplicants} // Pass total applicants to the JobCard
              onClick={() => navigate("/manage-jobs/details/" + regJob.id)}
              hideImage={true}
            />
            ))
          )}
        </Row>
        {/* Pagination for regular jobs */}
        {regJobs.length > 0 && (
          <PaginationComponent
            page={pagination.page}
            totalPages={pagination.totalPages}
            onPageChange={handlePageChange}
            loading={loadingJobs}
          />
        )}
      </div>

      {/* Alt Jobs Section */}
      <div className="altjob-container mt-5">
        <h3>Applied Alt Jobs</h3>
        <Row>
          {loadingAltJobs ? (
            <Spinner />
          ) : altJobError ? (
            <ErrorMessage message={altJobError} />
          ) : ajobs.length === 0 ? (
            <ErrorMessage message="You have not applied to any alt job" />
          ) : (
            
              ajobs.map((item) => (
                <JobCard
                  key={`alt-${item.id}`} // Added `alt-` prefix to ensure uniqueness
                  job={item}
                  onClick={() => navigate("/manage-jobs/details-alt/" + item.id)}
                  hideImage={true}
                />
              ))
              
       
          )}
        </Row>
        {/* Pagination for alt jobs */}
        {ajobs.length > 0 && (
          <PaginationComponent
            page={altPagination.page}
            totalPages={altPagination.totalPages}
            onPageChange={handleAltPageChange}
            loading={loadingAltJobs}
          />
        )}
      </div>
    </Container>
  );
};

export default JobApplicationSeeker;
