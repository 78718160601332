import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import "./JobListing.css";
import "./JobErrorMessage.css";
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Pagination,
} from "react-bootstrap";


import { useNavigate } from "react-router";
import { JobCardSeeker } from "../components";
import Swal from "sweetalert2";
import { CompanyService, JobService } from "../services";
import statesAndCities from '../services/statesAndCities.json';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import debounce from "lodash.debounce";  // Add this import

const ErrorMessage = ({ message }) => (
  <div className="no-jobs-message">
    <p>{message}</p>
  </div>
);

const JobListingSeeker = () => {
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0); // Initial value of 0
  const [jobs, setJobs] = useState([]);
  const [jobsF, setJobsF] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    isLastPage: false,
  });
  const [lastJob, setLastJob] = useState(null); // Track the last fetched job's ID
  const itemsPerPage = 6; // 6 jobs per page
  const initialItems = 18; // First 18 jobs to load (for the first 3 pages)

  const navigate = useNavigate();

  // Ref to track the job listings section
  const jobListingRef = useRef(null);

  // const [filters, setFilters] = useState({
  const [filters] = useState({
    slpStudents: ["N/A", "1 - 20", "20 - 40", "40 - 60", "60 - 80", "80 - 100"],
    salary: [
      "20 - 30",
      "30 - 40",
      "40 - 50",
      "50 - 60",
      "60 - 70",
      "70 - 80",
      "80 or more",
    ],
    companies: [],
    setting: [
      "School",
      "Clinic / Outpatient",
      "Home Health",
      "Hospital",
      "Acute Rehab",
      "Longterm Rehab",
      "University / Academic",
      "Non-Clinical",
    ],
    type: ["Hybrid", "Remote", "Inperson"],
    payrollCategory: ["1099", "W-2"],
    pslf: ["Yes", "No"],
    benefits: ["Yes", "No"],
    isDirectHire: ["Yes", "No"],
    classification: [
      "SLP",
      "SLPA",
      "SLP - CF",
      "SLP Supervisor",
      "Virtual Assistant",
      "Paraprofessional",
    ],
    companySize: [
      "1 - 10",
      "11 - 50",
      "51 - 200",
      "201 - 500",
      "501 - 1000",
      "1000+",
    ],
  });

  const [appFilters, setAppFilters] = useState({
    title: "",
    load: [],
    salary: [],
    companies: [],
    setting: [],
    type: [],
    payrollCategory: [],
    pslf: [],
    benefits: [],
    isDirectHire: [],
    classification: [],
    companySize: [],
    state: "",
    city: "",
  });

  useEffect(() => {
    if (selectedState) {
      setCityOptions(statesAndCities[selectedState] || []);
      setAppFilters((prevFilters) => ({ ...prevFilters, city: "" }));
    }
  }, [selectedState]);

  const hasActiveFilters = useMemo(() => {
    return Object.values(appFilters).some(
      (filter) => (Array.isArray(filter) ? filter.length > 0 : filter !== "")
    );
  }, [appFilters]);

  const getAllJobs = useCallback(
    async (fetchSize = initialItems, start = null, reset = false) => {
      if (loading) return;
  
      try {
        setLoading(true);
  
        if (reset) {
          setJobs([]);
          setLastJob(null); // Reset last job reference
          setPagination({
            page: 1,
            isLastPage: false,
          });
        }
  
        const res = await JobService.getAllJobs(fetchSize, start, false, appFilters);
  
        const isLastPage = res.docs.length < fetchSize;
  
        const jobPromises = res.docs.map(async (i) => {
          const jobDetails = i.data();
          const rec = await CompanyService.getCompanyDetails(jobDetails.recruiter);
          const applicants = await i.ref.collection("applicants").get();
          return {
            ...jobDetails,
            id: i.id,
            recruiterDetails: rec.data(),
            applicants: applicants.docs.map((x) => x.data()),
          };
        });
  
        const jobAry = await Promise.all(jobPromises);
  
        // Remove duplicates from the job list
        const uniqueJobs = reset
          ? jobAry
          : Array.from(new Map([...jobs, ...jobAry].map((item) => [item.id, item])).values());
  
        // Append unique jobs or replace if reset
        setJobs(uniqueJobs);
        // Update totalJobs based on the fetched jobs
        setTotalJobs(uniqueJobs.length);
  
        // Track the last job document (cursor for the next batch)
        if (res.docs.length > 0) {
          setLastJob(res.docs[res.docs.length - 1]); // Set the last job document
        }
  
        setPagination((prevPagination) => ({
          ...prevPagination,
          isLastPage,
        }));
      } catch (error) {
        console.error(error);
        Swal.fire({ text: "Error while fetching jobs" });
      } finally {
        setLoading(false);
      }
    },
    [appFilters, loading, jobs]
  );
  

  const applyFilters = useCallback(() => {
    let filteredJobs = jobs;

    filteredJobs = filteredJobs.filter((x) => {
      const nameMatch =
        !appFilters.title ||
        x.title.toLowerCase().includes(appFilters.title.toLowerCase());
      const caseload =
        !(appFilters.load.length > 0) || appFilters.load.includes(x.slpStudents);
      const salary =
        !(appFilters.salary.length > 0) || appFilters.salary.includes(x.salaryRange);
      const company =
        !(appFilters.companies.length > 0) ||
        appFilters.companies.includes(x.recruiterDetails.name);
      const type =
        !(appFilters.type.length > 0) || appFilters.type.includes(x.type);
      const classification =
        !(appFilters.classification.length > 0) ||
        appFilters.classification.includes(x.classification);
      const payrollCategory =
        !(appFilters.payrollCategory.length > 0) ||
        appFilters.payrollCategory.includes(x.payrollCategory);
      const pslf =
        !(appFilters.pslf.length > 0) || appFilters.pslf.includes(x.pslf);
      const isDirectHire =
        !(appFilters.isDirectHire.length > 0) ||
        appFilters.isDirectHire.includes(x.isDirectHire);
      const benefits =
        !(appFilters.benefits.length > 0) || appFilters.benefits.includes(x.benefits);
      const setting =
        !(appFilters.setting.length > 0) || appFilters.setting.includes(x.setting);
      const cs =
        !(appFilters.companySize.length > 0) ||
        appFilters.companySize.includes(x?.companySize);
        const stateMatch = !appFilters.state || appFilters.state === x.state;
        const cityMatch = !appFilters.city || appFilters.city === x.city;

      return (
        nameMatch &&
        caseload &&
        salary &&
        company &&
        type &&
        classification &&
        payrollCategory &&
        pslf &&
        isDirectHire &&
        benefits &&
        setting &&
        cs &&
        stateMatch &&  // Ensure the state matches
        cityMatch 
      );
    });

    setJobsF(filteredJobs);

  // Update totalJobs based on the filtered jobs
  setTotalJobs(filteredJobs.length);
}, [appFilters, jobs]);

  useEffect(() => {
    applyFilters();
  }, [jobs, appFilters, applyFilters]);

  const handleSearch = useCallback(
    debounce(() => {
      getAllJobs(initialItems, null, true); // Trigger a reset when applying filters
    }, 300),
    [appFilters, getAllJobs]
  );

  const clearFilter = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All filters will be cleared.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, clear filters!",
    }).then((result) => {
      if (result.isConfirmed) {
        setAppFilters({
          title: "",
          load: [],
          salary: [],
          companies: [],
          setting: [],
          type: [],
          payrollCategory: [],
          pslf: [],
          benefits: [],
          isDirectHire: [],
          classification: [],
          companySize: [],
          state: "", // Reset state filter
          city: "",  // Reset city filter
        });
        setSelectedState(""); // Reset selected state
        setSelectedCity(""); // Reset selected city
        setCityOptions([]); // Clear city options
        handleSearch();
      }
    });
  };
  
  const loadMoreItems = () => {
    if (!pagination.isLastPage && !loading) {
      getAllJobs(itemsPerPage * 3, lastJob); // Fetch 3 pages of jobs after the last fetched job
    }
  };

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: newPage,
      }));

      // Scroll to the job listings section when page changes
      if (jobListingRef.current) {
        window.scrollTo({
          top: jobListingRef.current.offsetTop - 100, // Adjust offset for any padding or filters
          behavior: "smooth", // Smooth scrolling
        });
      }
    }
  };

  const totalPages = useMemo(() => Math.ceil(jobsF.length / itemsPerPage), [jobsF]);

 /* eslint-disable react-hooks/exhaustive-deps */
useEffect(() => {
  getAllJobs(); // Initial load
}, []);
/* eslint-enable react-hooks/exhaustive-deps */


  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);
  
      // Example: Use the scrollY variable to trigger functionality
      if (scrollY > 200) {
        console.log('User scrolled past 200px');
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [scrollY]); // Added scrollY as a dependency
  
  
  
  

  return (
    <Container className="container">
      <Row className="mb-4">
        <Col>
          <div className="filters">
            <Row
              style={{ padding: "7.5px", paddingBottom: "0px" }}
              className="justify-content-center align-items-center my-2 mt-4"
            >
              {/* Job Setting */}
              <Col lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Job Setting</InputLabel>
                  <Select
                    value={appFilters.setting}
                    label="Job Setting"
                    onChange={(e) =>
                      setAppFilters({
                        ...appFilters,
                        setting: e.target.value,
                      })
                    }
                  >
                    {filters.setting.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              {/* Job Type */}
              <Col lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Job Type</InputLabel>
                  <Select
                    value={appFilters.type}
                    label="Job Type"
                    onChange={(e) =>
                      setAppFilters({
                        ...appFilters,
                        type: e.target.value,
                      })
                    }
                  >
                    {filters.type.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              {/* Job Role */}
              <Col lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Job Role</InputLabel>
                  <Select
                    value={appFilters.classification}
                    label="Job Role"
                    onChange={(e) =>
                      setAppFilters({
                        ...appFilters,
                        classification: Array.isArray(e.target.value)
                          ? e.target.value
                          : [e.target.value],
                      })
                    }
                  >
                    {filters.classification.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              {/* Location */}
              <Col lg={3}>
                <FormControl fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setAppFilters({ ...appFilters, state: e.target.value });
                    }}
                    label="State"
                  >
                    <MenuItem value="">-- Select --</MenuItem>
                    {Object.keys(statesAndCities).map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

             
              <Col lg={3}>
  <FormControl fullWidth>
    <InputLabel>City</InputLabel>
    <Select
      value={selectedCity}
      onChange={(e) => {
        setSelectedCity(e.target.value);
        setAppFilters({ ...appFilters, city: e.target.value });
      }}
      label="City"
      disabled={!selectedState}
      style={{ minWidth: '200px' }}  // Add minWidth styling here
      MenuProps={{
        disablePortal: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
    >
      <MenuItem value="">-- Select --</MenuItem>
      {cityOptions.map((city) => (
        <MenuItem key={city} value={city}>
          {city}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Col>
</Row>

            {/* More Filters */}
            <Row
              style={{ paddingTop: "7.5px" }}
              className="justify-content-center"
            >
              {/* Payroll Category */}
              <Col lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Payroll Category</InputLabel>
                  <Select
                    value={appFilters.payrollCategory}
                    label="Payroll Category"
                    onChange={(e) =>
                      setAppFilters({
                        ...appFilters,
                        payrollCategory: e.target.value,
                      })
                    }
                  >
                    {filters.payrollCategory.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              {/* PSLF */}
              <Col lg={3}>
                <FormControl fullWidth>
                  <InputLabel>PSLF Eligibility</InputLabel>
                  <Select
                    value={appFilters.pslf}
                    label="PSLF Eligibility"
                    onChange={(e) =>
                      setAppFilters({
                        ...appFilters,
                        pslf: e.target.value,
                      })
                    }
                  >
                    {filters.pslf.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              {/* Benefits */}
              <Col lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Benefits</InputLabel>
                  <Select
                    value={appFilters.benefits}
                    label="Benefits"
                    onChange={(e) =>
                      setAppFilters({
                        ...appFilters,
                        benefits: e.target.value,
                      })
                    }
                  >
                    {filters.benefits.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>

            {/* Clear Filters */}
            <Row style={{ paddingTop: "7.5px" }} className="justify-content-center">
              {hasActiveFilters && (
                <Col lg={3} className="d-grid">
                  <h6 style={{ paddingTop: "10px", textAlign: "center", color: "red" }}>
                    Please Clear Filter if any problems
                  </h6>
                  <Button className="btn btn-success" onClick={clearFilter}>
                    Clear
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
              
      {/* Job Listings and Alternative Jobs Button */}
      {jobsF.length > 0 && (
      <Row className="justify-content-between align-items-center my-4">
        <Col xs={6}>
          <h4>Total Jobs Found: {totalJobs}</h4>
        </Col>
      </Row>
    )}
          <Row className="justify-content-end align-items-center my-4">
      <Col xs="auto">
        <Button
          size="sm"
          variant="primary"
          onClick={() => navigate("/jobs-alt")}
        >
          Browse Alternate Jobs
        </Button>
      </Col>
    </Row>

        

      {jobsF.length < 1 && !loading && (
       
         <ErrorMessage message="No jobs found." />
       
      )}
      {loading && (
       <div className="spinner-container">
        <Spinner  />
        </div>
      )}

<Row ref={jobListingRef} className="job-listing-container">
        {!loading &&
          jobsF
            .slice((pagination.page - 1) * itemsPerPage, pagination.page * itemsPerPage)
            .map((item) => (
              <Col md={4} key={item.id}>
                <JobCardSeeker
                  job={item}
                  onClick={() => navigate("/manage-jobs/details/" + item.id)}
                />
              </Col>
            ))}
      </Row>

      {/* Pagination */}
      {!loading && jobsF.length > 0 && (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <Pagination className="my-2 align-items-center">
              <Pagination.First onClick={() => changePage(1)} disabled={pagination.page === 1 || loading} />
              <Pagination.Prev
                disabled={pagination.page === 1}
                onClick={() => changePage(pagination.page - 1)}
              />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={pagination.page === index + 1}
                  onClick={() => changePage(index + 1)}
                  disabled={loading}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => changePage(pagination.page + 1)}
                disabled={pagination.page === totalPages || loading}
              />
              <Pagination.Last onClick={() => changePage(totalPages)} disabled={pagination.page === totalPages || loading} />
            </Pagination>
          </div>

          {/* Only show Load More button on the 3rd page */}
          {pagination.page === 3 && !pagination.isLastPage && (
            <div className="d-flex justify-content-center">
              <Button onClick={loadMoreItems}>Load More Jobs</Button>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default JobListingSeeker;