import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import navigation hook for routing
import "./Footer.css";

export default function Footer() {
  const navigate = useNavigate(); // Use navigate to handle button clicks

  // Function to handle button clicks for navigation
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <footer className="footer-20192">
      <div className="site-section">
        <div className="container">
          <div className="cta d-block d-md-flex align-items-center px-5">
            <div className="fb-group">
              <h4>Join our facebook group</h4>
              <p className="text-light">
                Join our vibrant Facebook group and become part of a thriving
                community of over 6000 members dedicated to Speech and Language
                Pathology (SLP). Share insights, resources, and experiences,
                connect with fellow professionals, and collaborate to advance in
                your SLP journey. Whether you're seeking support, networking
                opportunities, or simply a place to share your passion for
                communication sciences, our group offers a welcoming space for
                growth and connection. Join us today and be a part of something
                meaningful in the SLP community!"
              </p>
            </div>
            <div className="ml-auto">
              <Button
                variant="light"
                style={{
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  borderRadius: "15px",
                  textTransform: "uppercase",
                  marginLeft: "30px",
                }}
                size="lg"
                onClick={() => handleNavigation("/join")} // Example navigation
              >
                Join us
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <Button
                className="footer-logo"
                variant="link"
                onClick={() => handleNavigation("/")}
              >
                1StopSLP
              </Button>
              <p className="copyright">&copy; 2024</p>
            </div>

            <div className="col-sm">
              <h3>Customers</h3>
              <ul className="list-unstyled links">
                <li>
                  <Button variant="link" onClick={() => handleNavigation("/jobs")}>
                    Job Listing
                  </Button>
                </li>
                <li>
                  <Button variant="link" onClick={() => handleNavigation("/profiles")}>
                    Profiles
                  </Button>
                </li>
              </ul>
            </div>

            <div className="col-sm">
              <h3>Company</h3>
              <ul className="list-unstyled links">
                <li>
                  <Button variant="link" onClick={() => handleNavigation("/about")}>
                    About us
                  </Button>
                </li>
                <li>
                  <Button variant="link" onClick={() => handleNavigation("/careers")}>
                    Careers
                  </Button>
                </li>
                <li>
                  <Button variant="link" onClick={() => handleNavigation("/contact")}>
                    Contact us
                  </Button>
                </li>
              </ul>
            </div>

            <div className="col-sm">
              <h3>Further Information</h3>
              <ul className="list-unstyled links">
                <li>
                  <Button
                    variant="link"
                    onClick={() => handleNavigation("/terms")}
                  >
                    Terms &amp; Conditions
                  </Button>
                </li>
                <li>
                  <Button
                    variant="link"
                    onClick={() => handleNavigation("/privacy")}
                  >
                    Privacy Policy
                  </Button>
                </li>
              </ul>
            </div>

            <div className="col-md-3">
              <h3>Follow us</h3>
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="developer">
        All rights reserved by 1StopSLP, 2024.
        <p>
  Made with <span role="img" aria-label="love">❤️</span> by an{" "}
  <a
    href="https://github.com/pelagicBKK"
    target="_blank"
    rel="noopener noreferrer"
  >
    SLP for SLPS
  </a>
</p>

      </div>
    </footer>
  );
}
