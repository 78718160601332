import React, { useState } from "react";
import { Button, TextField, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { db } from "../../firebase-config"; // Assuming Firebase

const AffiliateLinkGenerator = () => {
  const companyUser = useSelector((state) => state.authReducer.user); // Get company or profile data
  const [enteredUsername, setEnteredUsername] = useState(""); // Manually entered profile username
  const [commission, setCommission] = useState(""); // Store commission percentage
  const [actionType, setActionType] = useState("click"); // Action type (default to "click")
  const [expirationDate, setExpirationDate] = useState(""); // Expiration date
  const [affiliateLink, setAffiliateLink] = useState(""); // Store generated affiliate link
  const [errorMessage, setErrorMessage] = useState(""); // Store error messages

  // Function to generate the affiliate link
  const generateLink = async () => {
    if (companyUser) {
      const companyId = companyUser.id;

      // Validate that the entered profile username exists
      const profileRef = db.collection("profile").where("username", "==", enteredUsername);
      const profileSnapshot = await profileRef.get();

      if (profileSnapshot.empty) {
        setErrorMessage("Invalid profile username: No matching profile found.");
        return;
      }

      // Generate the affiliate link using the track-affiliate route
      const link = `https://slpmarket.com/#/route-handler?ref=${enteredUsername}&company=${companyId}&action=${actionType}&cm=${commission}`;
      setAffiliateLink(link);
      setErrorMessage(""); // Clear any previous errors

      // Save to Firebase (optional)
      try {
        await db.collection("companies").doc(companyId).collection("genericAffiliateLinks").add({
          affiliateId: enteredUsername,
          link,
          commission,
          actionType,
          expirationDate,
          createdAt: new Date(),
        });
      } catch (error) {
        console.error("Error saving affiliate link:", error);
        setErrorMessage("Failed to save the affiliate link.");
      }
    } else {
      setErrorMessage("Company user not found.");
    }
  };

  return (
    <div>
      <h3>Generate Generic Affiliate Link</h3>

      {/* Text field to enter the profile username */}
      <TextField
        fullWidth
        label="Enter Profile Username"
        value={enteredUsername}
        onChange={(e) => setEnteredUsername(e.target.value)}
        variant="outlined"
        margin="normal"
      />

      {/* Text field for commission percentage */}
      <TextField
        fullWidth
        label="Commission Percentage"
        value={commission}
        onChange={(e) => setCommission(e.target.value)}
        variant="outlined"
        margin="normal"
      />

      {/* Select for Action Type */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Action Type</InputLabel>
        <Select value={actionType} onChange={(e) => setActionType(e.target.value)}>
          <MenuItem value="click">Click</MenuItem>
          <MenuItem value="sign-up">Sign-up</MenuItem>
          <MenuItem value="purchase">Purchase</MenuItem>
        </Select>
      </FormControl>

      {/* Date field for expiration date */}
      <TextField
        fullWidth
        label="Expiration Date"
        type="date"
        value={expirationDate}
        onChange={(e) => setExpirationDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        margin="normal"
      />

      {/* Button to generate the affiliate link */}
      <Button
        variant="contained"
        color="primary"
        onClick={generateLink}
        disabled={!enteredUsername} // Disable if no username is entered
      >
        Generate Link
      </Button>

      {/* Display error messages if any */}
      {errorMessage && <p style={{ color: "red", marginTop: "10px" }}>{errorMessage}</p>}

      {/* Display the generated affiliate link */}
      {affiliateLink && (
        <div style={{ marginTop: "20px" }}>
          <p>Affiliate Link:</p>
          <TextField fullWidth value={affiliateLink} readOnly />
        </div>
      )}
    </div>
  );
};

export default AffiliateLinkGenerator;
