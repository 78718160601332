import axios from "axios";

const facebookAccessToken1 =
  "EAAL8kZAz6GqMBOxu0EVQUWmpKA4NXmWHUALWJ7PoL80m3FjXvJAZCZAve3PEZCwZBeMqLfKrCp2ocFCvgwfhJ8Q9XyS0PUlQZASzpvq3hWq25Hy3D5ZC5x9sgg41mtjJFsfUzKNzegjBMLxjKKpw3Tn6pUUOhXnQfCoB3lmZCVZBE1tIJocTBgJqbyHuJBU0XNAUMY1hHfcb5";

  // const pageId3 = 117956915593364; // Replace with your actual page ID
const pageId3 = 295649573632841;
const postUrl = `https://graph.facebook.com/v20.0/${pageId3}/feed`;

export const postJobInGroup = (title, link) => {
  const payload = {
    link: link,
    message: title,
    access_token: facebookAccessToken1,
    
  };
  console.log(postJobInGroup, 'postjobFB services')
  console.log(link, 'link')
  
  return axios
  .post(postUrl, payload)
  .then((res) => {
    console.log('Response:', res);
  })
  .catch((error) => {
    console.error('Error:', error.response ? error.response.data : error.message);
  });
};


// export const postJobInGroup = (title, link, pic) => {
//   const payload = {
//     link: link,
//     message: title,
//     access_token: facebookAccessToken1,
//     picture: pic,
//   };
//   return axios.post(postUrl, payload);
// };
