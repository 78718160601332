import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { Spinner } from 'react-bootstrap';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Modal
} from "@mui/material";

import statesAndCities from '../../services/statesAndCities.json'; 
import { FirebaseAuthService, JobService } from "../../services";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { postJobInGroup } from "../../services/FacebookPost";


  

// const caseLoadOptions = [
//   "1 - 10",
//   "10 - 20",
//   "20 - 30",
//   "30 - 40",
//   "40 - 50",
//   "50 - 60",
//   "60 - 70",
//   "70 - 80",
//   "80 - 90",
//   "90 - 100",
//   "100 or more",
// ];

const caseloadMgmtOptions = [
  "N/A",
  "1 - 10",
  "10 - 20",
  "20 - 30",
  "40 - 50",
  "50 - 60",
  "60 - 70",
  "70 - 80",
];

const slpStudentsOptions = [
  "N/A",
  "1 - 20",
  "20 - 40",
  "40 - 60",
  "60 - 80",
  "80 - 100",
];
// const annualSalaryRange = [
//   "40 - 50",
//   "50 - 60",
//   "60 - 70",
//   "70 - 80",
//   "80 - 90",
//   "90 - 100",
// ];

// const companySizes = [
//   "1 - 10",
//   "11 - 50",
//   "51 - 200",
//   "201 - 500",
//   "501 - 1000",
//   "1000+",
// ];

// const directTherapyStudents = ["1", "2", "3", "4 or more"];

const sitesToCoverOptions = ["1", "2", "3 or more"];

const salaryRangeOptions = [
  "20 - 30",
  "30 - 40",
  "40 - 50",
  "50 - 60",
  "60 - 70",
  "70 - 80",
  "80 or more",
  "N/A"
];
const jobtype = ["Hybrid", "Remote", "In-Person"];

const settings = [
  "School",
  "Clinic / Oupatient",
  "Home Health",
  "Hospital",
  "Acute Rehab",
  "Longterm Rehab",
  "University / Academic",
  "Non-Clinical",
  // "Alternatives",
];
const classifications = [
  "SLP",
  "SLPA",
  "SLP - CF",
  "SLP Supervisor",
  "Virtual Assistant",
  "Paraprofessional",
];

const jobHours = [
  "Part-Time",
  "Full-Time",
  "PRN",
  
];

const CreateJob = () => {
  const [formErrors, setFormErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.authReducer.user);
  // const [editMode, setEditMode] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [formData, setFormData] = useState({
    caseloadMgmt: "",
    slpStudents: "",
    directTherapyStudents: "",
    pslf: "",
    pslfDetails: "",
    benefits: "",
    benefitDetails: "",
    isDirectHire: "",
    telepathyPlatform: "",
    populationServed: [],
    salaryRange: "",
    isIndirectTimePaid: "",
    inDirectTimePaidDetails: "",
    sitesToCover: "",
    state: "",
    city: "",
    status: "active",
    title: "",
    type: "",
    setting: "",
    classification: "",
    companySize: "",
    jobHours: "",
  });

  const validateForm = () => {
    let errors = {};
  
    if (!formData.title) errors.title = "Job title is required";
    if (!formData.description) errors.description = "Job description is required";
    if (!formData.setting) errors.setting = "Job setting is required";
    if (!formData.state) errors.state = "State is required";
    if (!formData.city) errors.city = "City is required";
    if (!formData.classification) errors.classification = "Job role is required";
    if (!formData.jobHours) errors.jobHours = "Job hours are required";
    if (!formData.isDirectHire) errors.isDirectHire = "Direct hire status is required";
    if (!formData.payrollCategory) errors.payrollCategory = "Payroll category is required";
    if (!formData.salaryRange) errors.salaryRange = "Salary range is required";
  
    // Add other required fields here...
  
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  // const auth = getAuth();
  
  // const user3 = auth.currentUser;
  // // if (user3.providerId == "google.com") 
  // if (user3.providerId == "google.com") 
  //   {
  //   console.log(user3.providerId, 'providerID')

  // }

   //





   // Handle form field changes
const handleChange = (field, value) => {

  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [field]: value === "" ? true : false,  // Mark field as having an error if it's empty
  }));

  setFormData((prevState) => {
    const updatedState = { ...prevState, [field]: value };

    // Reset PSLF details if 'No'
    if (field === "pslf" && value === "No") {
      updatedState.pslfDetails = "";
    }

    // Reset benefits details if 'No'
    if (field === "benefits" && value === "No") {
      updatedState.benefitDetails = "";
    }

    // Reset indirect time paid details if 'No'
    if (field === "isIndirectTimePaid" && value === "No") {
      updatedState.inDirectTimePaidDetails = "";
    }

    // When state changes, reset the city
    if (field === "state") {
      setSelectedState(value);
      updatedState.city = ""; // Reset the city when the state changes
    }

    return updatedState;
  });
};



  
 
    const handleSubmit = async (event) => {
      event.preventDefault();
      
      if (!validateForm()) {
        Swal.fire({ text: "Please fill in all required fields." });
        return;
      }
    setLoading(true);
    const user = FirebaseAuthService.getUserId();
    
    try {
      const res = await JobService.createJob({
        ...formData,
        recruiter: user,
        company: userProfile.name,
        applicants: {},
      });
      console.log(res, "resy")
      if (formData.imageUrl) {
        JobService.storeJobsImage(formData.imageUrl, res.id);
      }
      
      const signinProvider = firebase.auth().currentUser?.providerData[0]?.providerId;

      console.log(signinProvider ,'testProvid')
      
   
  


      Swal.fire({ text: "Job Created Successfully" });
     if (signinProvider === "facebook.com") {

      postJobInGroup(
        `${formData.title} | ${formData.type} | ${formData.setting}`,
         `https://www.slpmarket.com/#/manage-jobs/details/${res.id}`,
       
        `https://firebasestorage.googleapis.com/v0/b/speech-path-group.appspot.com/o/jobs%2F${res.id}?alt=media&token=b8a25490-5e17-4fc7-8ea1-4079616a790d`
      )
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
    } 
    navigate("/manage-jobs");
  } catch (error) {
    console.log(error);
    if (error.message.toLowerCase().includes("imageurl")) {
      Swal.fire({ text: "Image Error - The image cannot be longer than 1 MB. Please make sure image type is PNG, JPG, or GIF" });
    } else {
      Swal.fire({ text: "Error Posting to FB group" });
      navigate("/manage-jobs");
    }
  } finally {
    setLoading(false); // Ensure loading is stopped in all cases
  }
};


  const isSearchForm = false;
  const handleFileUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result; // base64 string
        setFormData({ ...formData, [type]: result });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (selectedState) {
      setCityOptions(statesAndCities[selectedState]);
      setFormData((prevState) => ({
        ...prevState,
        city: prevState.city || '', // Keep city if it's already selected
      }));
    }
  }, [selectedState]);

 
  return (
    <form
      className="my-5"
      onSubmit={handleSubmit}
      style={{ width: isSearchForm ? "100%" : "60%", margin: "auto" }}
    >
      <div
                style={{
                  borderRadius: "15px",
                  boxShadow: "5px 5px 20px #0003",
                  overflow: "hidden",
                  padding: "20px",
                  margin: "40px",
                  minWidth: "316px",
                  // marginLeft:"25px",
                  backgroundColor: "#f6f6f6",
                }}
              >
                 
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ marginTop: "10px" }}
      >
        <Grid item xs={isSearchForm ? 4 : 12}>
          <TextField
            type="text"
            variant="outlined"
            color="primary"
            label="Job Title"
            onChange={(e) => handleChange("title", e.target.value)}
            value={formData?.title}
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            required={!isSearchForm}
            sx={{ mb: isSearchForm ? 1 : 2 }}
            error={formErrors.title}  // Add red border if there's an error
            helperText={formErrors.title ? "Job title is required" : ""}  // S
          />
        </Grid>
        {!isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Description"
              onChange={(e) => handleChange("description", e.target.value)}
              value={formData?.description}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              error={formErrors.description}  // Add red border if there's an error
              helperText={formErrors.description ? "Job description is required" : ""}  // S
              multiline
              rows={4}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}
        {!isSearchForm && (
           <Grid item xs={isSearchForm ? 4 : 12}>
           {/* Modal Info Button */}
           <Button 
    onClick={handleOpen} 
    variant="outlined" 
    size="small" 
    sx={{ mb: 1, mt: -1 }} // Reduced margin-top
  >
    Image Upload Info
  </Button>
         
           {/* Modal Definition */}
           <Modal open={open} onClose={handleClose}>
             <div style={{ padding: '20px', background: '#fff', margin: '100px auto', maxWidth: '400px' }}>
               <h3>Image Upload Guidelines</h3>
            
               <p>Please upload images in JPG, PNG, or GIF format.</p>
               <p>Max size allowed: 1 MB.</p>
               <p>Try a GIF! But make sure its not too big.</p>
               
               <Button onClick={handleClose} variant="contained">
                 Close
               </Button>
             </div>
           </Modal>
         
           {/* File Upload Field */}
           <TextField
             type="file"
             variant="outlined"
             color="primary"
             name="cover"
             accept="image/*"
             onChange={(e) => handleFileUpload(e, "imageUrl")}
             fullWidth
             size={isSearchForm ? "small" : "medium"}
             sx={{ mb: isSearchForm ? 1 : 2 }}
             placeholder="Upload Cover Image"
             error={formErrors.image}  // Add red border if there's an error
             helperText={formErrors.imageUrl ? "Image is required" : ""}  // S
           />
         </Grid>
        )}

       

      
        {/* <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Job type</InputLabel>
            <Select
              value={formData?.type}
              label="Job type"
              onChange={(e) => handleChange("type", e.target.value)}
              required={!isSearchForm}
            >
              {jobtype.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

{/* <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Company Size</InputLabel>
            <Select
              value={formData?.companySize}
              label="Company Siz"
              onChange={(e) => handleChange("companySize", e.target.value)}
              required={!isSearchForm}
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {companySizes.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        
        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Job Setting</InputLabel>
            <Select
              value={formData?.setting}
              label="Job Setting"
              onChange={(e) => handleChange("setting", e.target.value)}
              required={!isSearchForm}
              error={formErrors.setting}  // Add red border if there's an error
              helperText={formErrors.setting ? "Job Setting is required" : ""}  // S
            >
              {settings.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
        <FormControl fullWidth size="medium" sx={{ mb: 2 }}>
              <InputLabel>State</InputLabel>
              <Select
                value={formData.state}
                onChange={(e) => {
                  handleChange("state", e.target.value);
                  setSelectedState(e.target.value);
                }}
                label="State"
                required
                error={formErrors.state}  // Add red border if there's an error
                helperText={formErrors.state ? "State is required" : ""}  // S
              >
                <MenuItem value="">-- Select --</MenuItem>
                {Object.keys(statesAndCities).map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* City */}
          <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="medium" sx={{ mb: 2 }}>
  <InputLabel>City</InputLabel>
  <Select
    value={formData.city}
    onChange={(e) => handleChange("city", e.target.value)}
    label="City"
    
  >
    <MenuItem value="">-- Select --</MenuItem>
    {cityOptions.map((city, index) => (
      <MenuItem key={`${city}-${index}`} value={city}>
        {city}
      </MenuItem>
    ))}
  </Select>
</FormControl>

          </Grid>


        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Job Role</InputLabel>
            <Select
              value={formData?.classification}
              label="Job Role"
              onChange={(e) => handleChange("classification", e.target.value)}
              required={!isSearchForm}
              error={formErrors.classification}  // Add red border if there's an error
              helperText={formErrors.classification ? "Job Role is required" : ""}  // S
            >
              {classifications.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Hours Given/Worked</InputLabel>
            <Select
              value={formData?.jobHours}
              label="Hours Given/Worked"
              onChange={(e) => handleChange("jobHours", e.target.value)}
              required={!isSearchForm}
              error={formErrors.role}  // Add red border if there's an error
              helperText={formErrors.jobHours ? "Job Hours are required" : ""}  // S
              
            >
              {jobHours.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Direct Hire to Schools and/or Company?"</InputLabel>
            <Select
              value={formData?.isDirectHire}
              label="Direct Hire to Schools/Company?"
              onChange={(e) => handleChange("isDirectHire", e.target.value)}
              required={!isSearchForm}
              error={formErrors.isDirectHire}  // Add red border if there's an error
              helperText={formErrors.isDirecthire ? "Direct hire is required" : ""}  // S
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {["Yes", "No"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Payroll Category</InputLabel>
            <Select
              value={formData?.payrollCategory}
              label="Payroll Category"
              onChange={(e) => handleChange("payrollCategory", e.target.value)}
              required={!isSearchForm}
              error={formErrors.payrollCategory}  // Add red border if there's an error
              helperText={formErrors.payrollCategory ? "Payroll Category is required" : ""}  // S
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {["1099", "W-2"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Approximate Salary range / Hr</InputLabel>
            <Select
              value={formData?.salaryRange}
              label="Salary range"
              onChange={(e) => handleChange("salaryRange", e.target.value)}
              required={!isSearchForm}
              error={formErrors.salaryRange}  // Add red border if there's an error
              helperText={formErrors.salaryRange ? "Salary Range is required" : ""}  // S
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {salaryRangeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Job Type</InputLabel>
            <Select
              value={formData?.type}
              label="Job type"
              onChange={(e) => handleChange("type", e.target.value)}
              required={!isSearchForm}
              error={formErrors.type}  // Add red border if there's an error
              helperText={formErrors.type ? "Job Type is required" : ""}  // S
            >
              {jobtype.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {formData?.type === "Remote" && !isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="What platform does the school/facility use for teletherapy?"
              onChange={(e) =>
                handleChange("telepathyPlatform", e.target.value)
              }
              value={formData?.telepathyPlatform}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}

        {formData?.type === "Hybrid" && !isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="What platform does the school/facility use for teletherapy?"
              onChange={(e) =>
                handleChange("telepathyPlatform", e.target.value)
              }
              value={formData?.telepathyPlatform}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>PSLF Eligible?</InputLabel>
            <Select
              value={formData?.pslf}
              label="pslf"
              onChange={(e) => handleChange("pslf", e.target.value)}
              required={!isSearchForm}
              error={formErrors.pslf}  // Add red border if there's an error
              helperText={formErrors.pslf ? "PLSF is required" : ""}  // S
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {["Yes", "No"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {formData?.pslf === "Yes" && !isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Please explain the PSLF"
              onChange={(e) => handleChange("pslfDetails", e.target.value)}
              value={formData?.pslfDetails}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              multiline
              rows={4}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Benefits</InputLabel>
            <Select
              value={formData?.benefits}
              label="Benefits"
              onChange={(e) => handleChange("benefits", e.target.value)}
              required={!isSearchForm}
              error={formErrors.benefits}  // Add red border if there's an error
              helperText={formErrors.benefits ? "is required" : ""}  // S
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {["Yes", "No"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {formData?.benefits === "Yes" && !isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Please explain the benefits"
              onChange={(e) => handleChange("benefitDetails", e.target.value)}
              value={formData?.benefitDetails}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              multiline
              rows={4}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}

      

        {/* {formData.type === "Remote" && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="What platform does the school/facility use for teletherapy?"
              onChange={(e) =>
                handleChange("telepathyPlatform", e.target.value)
              }
              value={formData?.telepathyPlatform}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )} */}

<Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Approximate Caseload Management</InputLabel>
            <Select
              value={formData?.caseloadMgmt}
              label="caseloadMgmt"
              onChange={(e) => handleChange("caseloadMgmt", e.target.value)}
              required={!isSearchForm}
              error={formErrors.caseloadMgmt}  // Add red border if there's an error
              helperText={formErrors.caseloadMgmt ? "is required" : ""}  // S
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {caseloadMgmtOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Approximate Caseload Size</InputLabel>
            <Select
              value={formData?.slpStudents}
              label="Caseload Size"
              onChange={(e) => handleChange("slpStudents", e.target.value)}
              required={!isSearchForm}
              error={formErrors.slpStudents}  // Add red border if there's an error
              helperText={formErrors.slpStudents ? "Caseload size is required" : ""}  // S
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {slpStudentsOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>
              Approximate Direct Therapy Sizes Ind/Groups(choose multiple)
            </InputLabel>

            <Select
              value={formData?.directTherapyStudents || []}
              label="Direct Therapy Sizes Ind/Groups(choose multiple)"
              onChange={(e) =>
                handleChange("directTherapyStudents", e.target.value)
              }
              multiple
              error={formErrors.title}  // Add red border if there's an error
              helperText={formErrors.title ? "Job title is required" : ""}  // S
            >
              {["N/A", "1-1", "2", "3", "4 or more"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Population served (choose multiple)</InputLabel>
            <Select
              value={formData?.populationServed || []}
              label="Population served (choose multiple)"
              onChange={(e) => handleChange("populationServed", e.target.value)}
              multiple
              required={!isSearchForm}
              error={formErrors.populationServed}  // Add red border if there's an error
              helperText={formErrors.populationServed ? "Population is required" : ""}  // S
            >
              {[
                "Prek",
                "Elementary",
                "Middle school",
                "High school",
                "Adults",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
       
        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>Is indirect time paid</InputLabel>
            <Select
              value={formData?.isIndirectTimePaid}
              label="Is indirect time paid, if selecting no - please explain"
              onChange={(e) =>
                handleChange("isIndirectTimePaid", e.target.value)
              }
              required={!isSearchForm}
              // error={formErrors.isIndirectTimePaid}  // Add red border if there's an error
              // helperText={formErrors.isIndirectTimePaid ? "is required" : ""}  // S

            
              error={!!formErrors.inDirectTimePaidDetails}  // Make sure this converts to boolean
              helperText={formErrors.inDirectTimePaidDetails || ""}  // Show the error message or nothing
 

            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {["Yes", "No"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {formData?.isIndirectTimePaid === "Yes" && !isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Please state What indirect is defined as"
              onChange={(e) =>
                handleChange("inDirectTimePaidDetails", e.target.value)
              }
              value={formData?.inDirectTimePaidDetails}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              
              multiline
              rows={4}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}
         {formData?.isIndirectTimePaid === "No" && !isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <TextField
              type="text"
              variant="outlined"
              color="primary"
              label="Please explain about any/no indirect benefits"
              onChange={(e) =>
                handleChange("inDirectTimePaidDetails", e.target.value)
              }
              value={formData?.inDirectTimePaidDetails}
              fullWidth
              size={isSearchForm ? "small" : "medium"}
              required={!isSearchForm}
              error={formErrors.inDirectTimePaidDetails}  // Add red border if there's an error
              helperText={formErrors.inDirectTimePaidDetails ? "Please explain more about indirect" : ""}  // S             
              multiline
              rows={4}
              sx={{ mb: isSearchForm ? 1 : 2 }}
            />
          </Grid>
        )}
        <Grid item xs={isSearchForm ? 4 : 12}>
          <FormControl
            fullWidth
            size={isSearchForm ? "small" : "medium"}
            sx={{ mb: isSearchForm ? 1 : 2 }}
          >
            <InputLabel>How many sites are there to cover?</InputLabel>
            <Select
              value={formData?.sitesToCover}
              label="How many sites are there to cover?"
              onChange={(e) => handleChange("sitesToCover", e.target.value)}
              required={!isSearchForm}
              error={formErrors.sitesToCover}  // Add red border if there's an error
              helperText={formErrors.sitesToCover ? "Sites to cover is required" : ""}  // S
            >
              <MenuItem key="blank" value="">
                -- Select --
              </MenuItem>
              {sitesToCoverOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
      
        {!isSearchForm && (
          <Grid item xs={isSearchForm ? 4 : 12}>
            <center>
            <Button
                variant="contained"
                size="large"
                className="w-100"
                color="primary"
                type="submit">
              
              {loading ? 'Loading...' : 'Submit'}
              </Button>
            
            {loading && (
              <Spinner
                animation="border"
                role="status"
                style={{ marginLeft: '10px' }}
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            </center>
          </Grid>
        )}
      </Grid>
      </div>
    </form>
  );
};

export default CreateJob;