import React from 'react';
import { useSelector } from 'react-redux';
import AffiliatePayouts from "../components/affiliate/AffiliatePayouts";  
import AffiliateManagement from '../components/affiliate/AffiliateManagement';  
import AffiliateProfileLinks from '../components/affiliate/AffiliateProfileLinks';
import AffiliateLinkGenerator from '../components/affiliate/AffiliateLinkGenerator';
import JobAffiliateLinkGenerator from '../components/affiliate/JobAffiliateLinkGenerator';
import AffiliateLinkSharer from '../components/affiliate/AffiliateLinkSharer';
import './AffiliateDashboard.css';



const AffiliateEarnings = () => {
    return (
        <div className="widget">
            <h3>Your Earnings</h3>
            <p>Total Earned: $0.00</p>
            <p>Pending: $0.00</p>
        </div>
    );
};

const PerformanceMetrics = () => {
    return (
        <div className="widget">
            <h3>Performance Metrics</h3>
            <ul>
                <li>Total Clicks: 0</li>
                <li>Referral Sign-ups: 0</li>
                <li>Purchases: 0</li>
            </ul>
        </div>
    );
};

const PayoutHistory = () => {
    return (
        <div className="widget">
            <h3>Payout History</h3>
            <ul>
                <li>No payouts yet.</li>
            </ul>
        </div>
    );
};

const AffiliateDashboard = () => {
    const isCompany = useSelector((state) => state.authReducer.isCompany); // Use selector to determine if it's a company

    return (
        <div className="affiliate-dashboard">
            <h2>Affiliate Dashboard</h2>

            <div className="row">
                            <div className="full-width-widget">
                            <AffiliatePayouts /> {/* Render AffiliatePayouts for companies */}
                               
                            </div>
                            </div>
                            <div className="row">
                            <div className="full-width-widget">
                            <AffiliateManagement /> {/* Render AffiliateManagement for companies */}
                            </div>
                        </div>

            <div className="dashboard-widgets">
                {/* Company-specific components */}
                {isCompany && (
                    <>
                        <div className="row">
                        <div className="widget">
                            <JobAffiliateLinkGenerator/>
                            </div>
                            <div className="widget">
                            <AffiliateLinkGenerator />
                            </div>
                            
                        </div>
                      
                    </>
                )}

            
              

                {/* Side-by-side: Earnings and Payout History */}
                {!isCompany && (
                <div className="row earnings-and-payout">
                     <div className="row">
                    <div className="widget">
                        <AffiliateEarnings />
                    </div>
                    <div className="widget">
                        <PayoutHistory />
                    </div>
                </div>

             
                <div className="row">
                    <div className="widget">
                        <PerformanceMetrics />
                    </div>
                    </div>
              

            
                    <div className="row">
                        <div className="widget">
                            <AffiliateProfileLinks />
                            <AffiliateLinkSharer />
                            

                        </div>
                    </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AffiliateDashboard;
